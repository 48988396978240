import { endpoints } from "../../api/ApiEndPoints";
import { putRequest } from "../../services/apiCaller";

export const logoutUser = () => () => {
    let logoutUrl = endpoints.logoutUser;

    putRequest(logoutUrl)
        .then((res) => {
            if (res.status === 200) {
                localStorage.clear();
                window.location.href = "/";
            }
        })
        .catch((err) => {
            
        })
};