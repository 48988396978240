import React, { useEffect, useState } from "react";
import LOGO from "../../assets/images/png/logo.png";
import HamBurger from "../../assets/images/png/hamburgermenu.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
// import profileLogo from "../../assets/images/png/sidemenuIcons/Login.png";
import profileLogo from "../../assets/images/svg/sideMenuicons/user.svg";
import Howtogaincoins from "../../assets/images/svg/sideMenuicons/coins.svg";
import ReferEarn from "../../assets/images/svg/sideMenuicons/refer.svg";
import setting from "../../assets/images/svg/sideMenuicons/settings.svg";
import faq from "../../assets/images/svg/sideMenuicons/information.svg";
import TandC from "../../assets/images/svg/sideMenuicons/terms-and-conditions.svg";
import feedback from "../../assets/images/png/sidemenuIcons/FEEDBACK.png";
import Coin from "../../assets/images/png/coin.png";
import profile from "../../assets/images/svg/profile-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setIsSideMenuOpened } from "./headerSlice";
import loginIcon from "../../assets/images/png/sidemenuIcons/LoginIcon1.png";
import logout from "../../assets/images/png/sidemenuIcons/Logout.png";
import { logoutUser } from "./headerApi";
import { getFromLocalStorage } from "../../services/helper/Index";
import hitSixIcon from "../../assets/images/png/sidemenuIcons/Hit6.png";
import MpIcon from "../../assets/images/svg/sideMenuicons/MP.svg";
import VcttIcon from "../../assets/images/svg/sideMenuicons/ball.svg";

function Header() {
  const dispatch = useDispatch();

  const { isSideMenuOpened } = useSelector((state) => state.header);
  const { isSignInSuccess, userDetails } = useSelector((state) => state.signIn);
  const location = useLocation()
  const navigate = useNavigate()

  const [activeHeaderTab, setActiveHeaderTab] = useState("")

  const toggleSideMenu = () => {
    dispatch(setIsSideMenuOpened(!isSideMenuOpened));
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveHeaderTab("/")
    }
    else if (location.pathname === "/howtogaincoins") {
      setActiveHeaderTab("howtogaincoins")
    }
    else if (location.pathname.search("hit6") >= 0) {
      setActiveHeaderTab("hit6")
    }
    else if (location.pathname.search("mpcric") >= 0) {
      setActiveHeaderTab("mpcric")
    }
    else if (location.pathname.search("vctt") >= 0) {
      setActiveHeaderTab("VCTT")
    } else {
      setActiveHeaderTab("")
    }
  }, [location])


  const token = getFromLocalStorage("gk");


  const navigateToGame = (GameLink) => {
    const token = getFromLocalStorage("gk");
    if (token && isSignInSuccess) {
      navigate(GameLink)
    } else {
      navigate("/signin")
    }
  }


  return (
    <React.Fragment>
      <div className="header" id="page-layoutHeader">
        <div className="layout-header">
          <div className="mainHeader">
            <div className="container">
              <div className="mainHeader-container">
                <div className="mainHeader-leftSection">
                  <div
                    className="mainHeader-hamBurger"
                    onClick={() => toggleSideMenu()}
                  >
                    <img
                      src={HamBurger}
                      alt="Loading"
                      role="none"
                      loading="eager"
                    />
                  </div>
                  <div className="mainHeader-logo">
                    <Link to="/">
                      <img
                        src={LOGO}
                        alt="Loading"
                        role="none"
                        loading="eager"
                      />
                    </Link>
                  </div>
                </div>
                <div className="mainHeader-middleSection hide-mobile show-desktop">
                  <ul>
                    {token && <li className="gainCoins active" >
                      <Link
                        className={activeHeaderTab === "howtogaincoins" ? "mainHeader-item active" : "mainHeader-item"}
                        role="none"
                        to={"/howtogaincoins"}
                      >
                        <div>HOW TO GAIN COINS</div>
                      </Link>
                    </li>}
                    {token && <li className="hit" role="none" >
                      <div className={activeHeaderTab === "hit6" ? "mainHeader-item active" : "mainHeader-item"}>
                        <div onClick={() => navigateToGame("/game/hit6")}>Hit 6</div>
                      </div>
                      {/* <div className="navlink-dropdown hitSix">
                        <ul>
                          <Link to={"/game/hit6"}><li>Play</li></Link>
                          <Link to={"/game/hit6/result"}><li>Results</li></Link>
                          <Link to={"/game/hit6/ldb"}><li>leaderboard</li></Link>
                          <Link to={"/game/hit6/prizeTable"}><li>Prizes</li></Link>
                        </ul>
                      </div> */}
                    </li>}
                    {token && <li className="mp" role="none" >
                      <div className={activeHeaderTab === "mpcric" ? "mainHeader-item active" : "mainHeader-item"} role="none">
                        <div onClick={() => navigateToGame("/game/mpcric/")} >MATCH PREDICTOR</div>
                      </div>
                      {/* <div className="navlink-dropdown mp-dropdown">
                        <ul>
                          <Link to={"/game/mpcric/"}><li>Play</li></Link>
                          <Link to={"/game/mpcric/history"}><li>Game History</li></Link>
                          <Link to={"/game/mpcric/prizeTable"}><li>Prizes</li></Link>
                          <Link to={"/game/mpcric/gameRules"}><li>Game Rules</li></Link>
                        </ul>
                      </div> */}
                    </li>}
                    {token && <li className="vctt" role="none" >
                      <div className={activeHeaderTab === "VCTT" ? "mainHeader-item active" : "mainHeader-item"}>
                        <div onClick={() => navigateToGame("/game/vctt/")}>VCTT</div>
                      </div>
                      {/* <div className="navlink-dropdown vctt-dropdown">
                        <ul>
                          <Link to={"/game/vctt"}><li>How to Play</li></Link>
                          <Link to={"/game/vctt"}><li>Play</li></Link>
                          <Link to={"/game/vctt"}><li>leaderboard</li></Link>
                          <Link to={"/game/vctt"}><li>Results</li></Link>
                        </ul>
                      </div> */}
                    </li>}
                    {/* <li className="prize" role="none">
                      <Link className="mainHeader-item" to="/prizes">
                        <div>PRIZE</div>
                      </Link>
                    </li> */}
                    {token && <li className="more" role="none">
                      <div className="mainHeader-item">
                        <div>MORE</div>
                      </div>
                      <div className="navlink-dropdown more-dropdown">
                        <ul>
                          <Link to="/referandearn">
                            <li>
                              <div className="icon_container">
                                <img alt="icon" src={ReferEarn} />
                              </div>
                              <div className="text_container">
                                Refer and Earn
                              </div>
                            </li>
                          </Link>
                          {/* <li>
                                                        <div className="icon_container">
                                                            <img alt='icon' src={BecomeaVIP} />
                                                        </div>
                                                        <div className="text_container">Become A VIP</div>
                                                    </li> */}
                          <Link to="/settings">
                            <li>
                              <div className="icon_container">
                                <img alt="icon" src={setting} />
                              </div>
                              <div className="text_container">Settings</div>
                            </li>
                          </Link>
                          <Link to="/faqs">
                            <li>
                              <div className="icon_container">
                                <img alt="icon" src={faq} />
                              </div>
                              <div className="text_container">FAQs</div>
                            </li>
                          </Link>
                          <Link to="/gamesfeedback">
                            <li>
                              <div className="icon_container">
                                <img alt="icon" src={feedback} />
                              </div>
                              <div className="text_container">Feedback</div>
                            </li>
                          </Link>
                          <Link to="/terms-and-conditions">
                            <li>
                              <div className="icon_container">
                                <img alt="icon" src={TandC} />
                              </div>
                              <div className="text_container">T&Cs</div>
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </li>}
                  </ul>
                </div>
                <div className="mainHeader-rightSection">
                  {isSignInSuccess ? (
                    <div className="mainHeader-afterLogin">
                      <div className="mainHeader-profile">
                        <div className="coin_section">
                          <div className="userid">
                            {userDetails?.username &&
                              `#${userDetails?.username}`}
                          </div>
                          <div className="user_coin">
                            <div className="coin_container">
                              <img src={Coin} alt="coinIcon" />
                            </div>

                            <div>{userDetails?.coin}</div>
                          </div>
                        </div>
                        <div className="profileIcon">
                          <div className="show-desktop hide-mobile">
                            {userDetails?.avatarPath ?
                              <img src={userDetails?.avatarPath} alt="coinIcon" />
                              :
                              <img src={profile} alt="coinIcon" />
                            }
                          </div>
                          <Link to={"/personal-details"}> <div className="show-mobile">
                            {userDetails?.avatarPath ?
                              <img src={userDetails?.avatarPath} alt="coinIcon" />
                              :
                              <img src={profile} alt="coinIcon" />
                            }
                          </div>
                          </Link>
                          <div className="navlink-dropdown profile">
                            <ul>
                              <Link to="/personal-details">
                                <li>
                                  <div className="icon_container">
                                    <img alt="icon" src={loginIcon} />
                                  </div>
                                  <div className="text_container">
                                    My profile
                                  </div>
                                </li>
                              </Link>
                              <li onClick={() => dispatch(logoutUser())}>
                                <div className="icon_container">
                                  <img alt="icon" src={logout} />
                                </div>
                                <div className="text_container">
                                  Logout
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mainHeader-beforeLogin">
                      <div className="mainHeader-loginRegister">
                        <Link to={"/signin"}>
                          <button className="btn outline" id="LOGIN">
                            LOGIN
                          </button>
                        </Link>
                        <Link to={"/signup"}>
                          <button className="btn signup" id="SIGNUP">
                            SIGNUP
                          </button>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`sidemenu ${isSideMenuOpened ? " " : "hide"}`}>
          <div className="sidemenu-head">
            {!token && <div className="sidemenu-head-text">
              <p>
                Register now and <br />
                receive upto 8 Free Coins immediately!
              </p>
              <div className="mainHeader-loginRegister">
                <Link to={"/signup"}>
                  <button className="btn outline" id="SIGNUP">
                    Claim now
                  </button>
                </Link>
              </div>
            </div>}
            {token && <div className="mainHeader-logo">
              <Link to="/">
                <img
                  src={LOGO}
                  alt="Loading"
                  role="none"
                  loading="eager"
                />
              </Link>
            </div>}
            <div
              className="sidemenu-closeIcon"
              onClick={() => toggleSideMenu()}
            >
              <AiOutlineCloseCircle />
            </div>
          </div>
          <div className="sidemenu-body">
            <ul>
              <li>
                <div className="left">
                  <div className="icon_container">
                    <img alt="icon" src={profileLogo} />
                  </div>
                  {!token && <div className="list_title">
                    <Link to={"/signin"}>
                      Login
                    </Link>
                  </div>}
                  {!token && <div>
                    <Link to={"/signup"}>
                      <button className="btn signup" id="SIGNUP">
                        Sign up
                      </button>
                    </Link>
                  </div>}
                  {token && <div onClick={() => toggleSideMenu()}>
                    <Link to={"/personal-details"}>
                      <div className="list_title">My Profile</div>
                    </Link>
                  </div>}
                </div>
              </li>
              {token && <li onClick={() => toggleSideMenu()}>
                <Link to={"/howtogaincoins"}>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={Howtogaincoins} />
                    </div>
                    <div className="list_title">How to gain coins</div>
                  </div>
                </Link>
              </li>}
              {token && <li onClick={() => toggleSideMenu()}>
                <Link to={"/referandearn"}>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={ReferEarn} />
                    </div>
                    <div className="list_title">Refer and Earn</div>
                  </div>
                </Link>
              </li>}
              {/* <li>
                                <div className="left">
                                    <div className="icon_container">
                                        <img alt='icon' src={BecomeaVIP} />
                                    </div>
                                    <div className="list_title">
                                        Become A VIP
                                    </div>

                                </div>
                            </li> */}
              {token && <Link to={"/settings"} onClick={() => toggleSideMenu()}>
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={setting} />
                    </div>
                    <div className="list_title">Settings</div>
                  </div>
                </li>
              </Link>}
              {token && <Link to={"/game/hit6"} onClick={() => toggleSideMenu()}>
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={hitSixIcon} />
                    </div>
                    <div className="list_title">Hit 6</div>
                  </div>
                </li>
              </Link>}
              {token && <Link to={"/game/mpcric"} onClick={() => toggleSideMenu()}>
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={MpIcon} />
                    </div>
                    <div className="list_title">Match Predictor</div>
                  </div>
                </li>
              </Link>}
              {token && <Link to={"/game/vctt"} onClick={() => toggleSideMenu()}>
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={VcttIcon} />
                    </div>
                    <div className="list_title">Virtual Catch The Trend</div>
                  </div>
                </li>
              </Link>}
              {/* {token && games?.games?.map((game, index) => {
                return (
                  <SideMenuAccordion src={game.icon} title={game.category} gameData={game} key={index} />
                )
              })} */}
              {<Link to={"/faqs"} onClick={() => toggleSideMenu()}>
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={faq} />
                    </div>
                    <div className="list_title">FAQs</div>
                  </div>
                </li>
              </Link>}
              {token && <Link to={"/gamesfeedback"} onClick={() => toggleSideMenu()}>
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={feedback} />
                    </div>
                    <div className="list_title">Feedback</div>
                  </div>
                </li>
              </Link>}
              {<Link to={"/terms-and-conditions"} onClick={() => toggleSideMenu()}>
                <li>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={TandC} />
                    </div>
                    <div className="list_title">T&Cs</div>
                  </div>
                </li>
              </Link>}
              {token &&
                <li onClick={() => dispatch(logoutUser())}>
                  <div className="left">
                    <div className="icon_container">
                      <img alt="icon" src={logout} />
                    </div>
                    <div className="list_title">Logout</div>
                  </div>
                </li>}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}

export default Header;
