import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Footer from '../components/common/Footer'
import Header from '../components/header/Header'

function HomeLayout({ children, element }) {

  const { isSideMenuOpened } = useSelector((state) => state.header);
  useEffect(() => {
    window.scrollTo(0,0);
  });

  return (
    <React.Fragment>
      <div className={isSideMenuOpened ? "homepage_layout sidemenu-opened" : "homepage_layout"}>
        <Header />
        {element}
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default HomeLayout