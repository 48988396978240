import React from "react";
import logo from "../../assets/images/png/signupSuccess.png"
import { useNavigate } from "react-router";

const SignupSuccess = () => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <div className='sign_wrapper'>
                <div className="container">
                    <div className="password_wrapper">
                        <div>
                            <img src={logo} alt="logo" width={80} height={80} />
                        </div>
                        <div>
                            <h2>Registration Successful</h2>
                        </div>
                        <div>
                            <div>Congratulations, you account has been <br /> Successfully Created.</div>
                        </div>
                        <button className="btn btn-strech" onClick={() => navigate("/")}>CONTINUE</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default SignupSuccess;