import { endpoints } from "../../api/ApiEndPoints";
import { setFaqResponse } from "./faqSlice";
import { getRequest } from "../../services/apiCaller";
import { getFromLocalStorage } from "../../services/helper/Index";


export const faq = () => (dispatch) => {
    const token = getFromLocalStorage("gk");
    if (token) {
        let faqUrl = endpoints.faqs;

        getRequest(faqUrl, false)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setFaqResponse(res?.data?.faqs))
                }
            })
            .catch((err) => {
                const errorResponse = err.errorResponse
                dispatch(setFaqResponse(errorResponse.data.message));
            })
    } else {
        let faqUrl = endpoints.faqBeforeLogin;

        getRequest(faqUrl, false)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setFaqResponse(res?.data?.faqs))
                }
            })
            .catch((err) => {
                const errorResponse = err.errorResponse
                dispatch(setFaqResponse(errorResponse.data.message));
            })
    }
}