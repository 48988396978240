import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSignInSuccess: false,
    signInSuccessMessage: "",
    userDetails: {},
    signErrorMessage: "",
    signinLoading:  false,
    referredUser: {}
};

export const signSlice = createSlice({
    name: "signIn",
    initialState,
    reducers: {
        setIsSignInSuccess: (state, action) => {
            state.isSignInSuccess = action.payload;
        },
        setSignInSuccessMessage: (state, action) => {
            state.signInSuccessMessage = action.payload;
        },
        setUserDetails: (state, action) => {
            state.userDetails = action.payload;
        },
        setSignErrorMessage: (state, action) => {
            state.signErrorMessage = action.payload;
        },
        setSignInLoading: (state, action) => {
            state.signinLoading = action.payload;
        },
        setReferredUser: (state,action) => {
            state.referredUser = action.payload;
        }
    }
});

export const {
    setIsSignInSuccess,
    setSignInSuccessMessage,
    setUserDetails,
    setSignErrorMessage,
    setSignInLoading,
    setReferredUser
} = signSlice.actions;


export default signSlice.reducer;
