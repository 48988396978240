import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import liveIcon from "../../assets/images/png/liveIcon.png"


function TrendingEvents(props) {
  const { socketMatchesData } = props
  return (
    <React.Fragment>
      {socketMatchesData && socketMatchesData.length > 0 &&
        <div className="container">
          <div className="super_card_title">
            <p>{"Latest Scores and Results"}</p>
          </div>
          <div className="trending_card_section">
            <Splide
              options={{
                // type: "loop",
                perPage: 4,
                // focus: "center",
                arrows: socketMatchesData.length > 1 ? true : false,
                gap: 10,
                breakpoints: {
                  1273: {
                    perPage: 3,
                    gap: 10,
                  },
                  1100: {
                    perPage: 2,
                    gap: 10,
                  },
                  656: {
                    perPage: 1,
                    gap: 10,
                    arrows: false,
                  },
                },
              }}
            >
              {socketMatchesData && socketMatchesData.length > 0 && socketMatchesData?.map((match, ind) => {
                return (
                  <SplideSlide key={ind}>
                    <div className="card_wrapper" >
                      <div className="match_card">
                        <div className="top_section">
                          <div className="match_title">MATCH  {match?.matchNumber}</div>
                        </div>
                        <div className="bottom_section">
                          <div className="first_team teamImg">
                            <img src={match?.teamDetails && match?.teamDetails[0]?.logo_url} className="opponent_logo" alt="first team logo" />
                            <p>{match?.teamDetails && match?.teamDetails[0]?.abbr}</p>
                            <div className="score">{match?.teamDetails && match?.teamDetails[0]?.score}</div>
                          </div>
                          {match?.matchStatus == "Live" ?
                            <div className="liveIcon">
                              <img className='liveIcon' src={liveIcon} alt="lice ogo" />
                            </div>
                            :
                            null}
                          <div className="second_team teamImg">
                            <img src={match?.teamDetails && match?.teamDetails[1]?.logo_url} className="opponent_logo" alt="second team logo" />
                            <p>{match?.teamDetails && match?.teamDetails[1]?.abbr}</p>
                            <div className="score">{match?.teamDetails && match?.teamDetails[1]?.score}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                )
              })
              }
            </Splide>
          </div>
        </div>
      }
    </React.Fragment>
  )
}

export default TrendingEvents