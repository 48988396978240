import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: false,
    isApiCall: false,
    error: false,
    successMsg: ""
};

export const settingSlice = createSlice({
    name: "setting",
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload;
        },
        setIsApiCall: (state, action) => {
            state.isApiCall = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setSuccessMsg: (state, action) => {
            state.successMsg = action.payload;
        }
    }
});

export const {
    setLoader,
    setIsApiCall,
    setError,
    setSuccessMsg
} = settingSlice.actions;

export default settingSlice.reducer;