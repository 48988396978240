import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { faq } from "./faqApi";
import FaqAccordion from './FaqAccordion';

function Faq() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(faq());
    }, []);
    const { faqResponse } = useSelector((state) => state.faqSlice);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <React.Fragment>
            <div className="faq_wrapper">
                <div className="container">
                    <div className="faq_container">
                        <div className="faq_head">
                            <div className="title brand_secondary" >FAQS</div>
                        </div>
                        <div className="faq_Accordion">
                            {faqResponse && faqResponse.map((obj, ind) => {
                                return (
                                    <div className="faq_Accordion_Content" key={ind}>
                                        <FaqAccordion title={obj?.question} content={obj.answers} />
                                    </div>
                                );
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default Faq