import React, { useEffect, useState } from 'react'
// import userLogo from "../../assets/images/svg/profile_male.svg"
import profile from "../../assets/images/svg/profile-icon.svg"
import pen from "../../assets/images/svg/pen.svg"
import ModalBox from "../common/ModalBox"
import { BsCheck } from "react-icons/bs"
import UserNameDialog from "./UserNameDialog"
import { Link } from "react-router-dom"
import gainCoin from "../../assets/images/png/gaincoin.png";
import gainCoinbg1 from "../../assets/images/png/gaincoinbg1.png";
import gainCoinbg2 from "../../assets/images/png/gaincoinbg2.png";
import { useDispatch, useSelector } from 'react-redux'
import { accoladesUser, getAllAvatarImages, isEighteenPlus, updateAvatar, updateUserDetails, validateUserName } from './myProfileApi'
import { setErrorWhileSaving, setIsErrorWhileSaving, setIsUsernameAvailable, setSuccessMessage, setUserNameAvailbleMessage } from './myProfileSlice'
import { getReferredByUser } from '../signIn/signInApi';

function MyProfile() {
    const { userDetails,referredUser } = useSelector(state => state.signIn)
    const { isUsernameAvailable, userNameAvailbleMessage, allAvatarImages, errorWhileSaving, isErrorWhileSaving, successMessage, accoladesDetails } = useSelector(state => state.profile)

    const [isInputEnable, setIsInputEnable] = useState(true)

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState("")
    const [uModal, setUmodal] = useState(false)
    const [dobError, setDobError] = useState("")
    const [dob, setDob] = useState("")
    const [searchQuery, setSearchQuery] = useState("");
    const [fullName, setFullName] = useState("");
    const dispatch = useDispatch()


    const [gender, setGender] = useState(userDetails?.gender);

    const [inputPersonalDetails, setinputPersonalDetails] = useState({})

    function close() {
        setOpen(false);
    }
    const handleGenderChange = (event) => {
        setGender(event.target.value);
        setinputPersonalDetails({ ...inputPersonalDetails, gender: event.target.value })
        dispatch(setSuccessMessage(""))
        dispatch(setErrorWhileSaving(""))
        dispatch(setUserNameAvailbleMessage(""))
    }

    const handleAdressChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setinputPersonalDetails((prevState) => ({
            ...prevState,
            address: {
                ...prevState.address,
                [name]: value,
            },
        }));
        dispatch(setSuccessMessage(""))
        dispatch(setErrorWhileSaving(""))
        dispatch(setUserNameAvailbleMessage(""))
    }
    const handleNameChange = (e) => {
        const value = e.target.value
        setinputPersonalDetails((prevState) => ({
            ...prevState,
            fullName: value,
        }));
        setFullName(value)
        dispatch(setSuccessMessage(""))
        dispatch(setErrorWhileSaving(""))
        dispatch(setUserNameAvailbleMessage(""))

    }

    const handleDobChange = (e) => {
        dispatch(setSuccessMessage(""))
        dispatch(setErrorWhileSaving(""))
        dispatch(setUserNameAvailbleMessage(""))
        const value = e.target.value
        const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
        setDob(value)
        setinputPersonalDetails((prevState) => ({
            ...prevState,
            dob: value,
        }));
        if (value !== "") {
            if (!dateRegex.test(value)) {
                setDobError("Invalid date format (DD-MM-YYYY)")
                dispatch(setIsErrorWhileSaving(true))
            }
            else {
                setDobError("")
                dispatch(setIsErrorWhileSaving(false))

            }
        } else {
            setDobError("")
            dispatch(setIsErrorWhileSaving(false))
        }
    }

    useEffect(() => {
        setinputPersonalDetails((prevState) => ({
            ...prevState,
            address: { ...userDetails.address },
            dob: userDetails?.dob ? getDate(userDetails?.dob) : "",
            username: userDetails?.username,
            fullName: userDetails?.fullName
        }));
        getDate(userDetails?.dob)
        setGender(userDetails?.gender)
        setFullName(userDetails?.fullName || "")
    }, [userDetails])




    let debounceTimer;
    const handleSearchChange = (event) => {
        dispatch(setIsUsernameAvailable(false))
        dispatch(setUserNameAvailbleMessage(""))
        setinputPersonalDetails((prevState) => ({
            ...prevState,
            username: event.target.value
        }));
        const value = event.target.value;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            setSearchQuery(value);
        }, 700);
    };


    useEffect(() => {
        if (searchQuery !== "") {
            dispatch(validateUserName(searchQuery))
        }
    }, [searchQuery, dispatch]);


    const handleSelectImage = (icon) => {
        setSelected(icon)
        dispatch(updateAvatar(icon))
    }


    useEffect(() => {
        dispatch(getAllAvatarImages())
        dispatch(getReferredByUser())
    }, [dispatch])

    const getDate = (UTCDate) => {
        if (UTCDate !== "" || UTCDate !== undefined) {
            const dateObj = new Date(UTCDate);
            const day = dateObj.getDate().toString().padStart(2, '0');
            const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
            const year = dateObj.getFullYear().toString();
            const formattedDate = `${day}-${month}-${year}`;
            setDob(formattedDate)
            return formattedDate
        }
        return ""
    }

    const handleSave = (e, alluserDetails) => {
        e.preventDefault()
        console.log("alluserDetails: ", alluserDetails);
        if (alluserDetails?.dob.trim() === "") {
            setDobError("DOB is required")
            dispatch(setIsErrorWhileSaving(true))
        }
        else if (!isEighteenPlus(alluserDetails.dob)) {
            setDobError("DOB must be 18 or above")
            dispatch(setIsErrorWhileSaving(true))
        } else if (isErrorWhileSaving) {
            return;
        }
        else {
            dispatch(updateUserDetails(alluserDetails, setIsInputEnable))
            dispatch(setErrorWhileSaving(""))
            dispatch(setIsErrorWhileSaving(false))
        }

    }

    useEffect(() => {
        dispatch(accoladesUser());
        return () => {
            dispatch(setSuccessMessage(""))
            dispatch(setErrorWhileSaving(""))
            dispatch(setUserNameAvailbleMessage(""))
            setDobError("")
            dispatch(setIsErrorWhileSaving(false))
        }
    }, [dispatch])

    return (
        <>
            {
                open ?
                    <ModalBox dOpen={open} dClose={close} isClosable={true} >
                        <div className="profile_icons_box profile">
                            {allAvatarImages?.map((icon, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`profice_icon_wrapper ${selected === icon ? "active" : ""}`}
                                        // data-borer={selected === icon.id ? "3px" : "0px"}
                                        style={{ border: selected === icon ? "3px solid #FEA792" : "none" }}
                                        onClick={() => handleSelectImage(icon)}
                                    >
                                        {selected === icon ? <BsCheck className="icon_check" /> : null}
                                        <img src={icon} className="profile_icon" alt={icon.name} />
                                    </div>
                                )
                            })}
                        </div>
                    </ModalBox> : null
            }
            {
                <UserNameDialog open={uModal} close={() => setUmodal(false)} />
            }
            <div className="container">
                <div className="my_profile_container">
                    <div className="user_id">
                        {referredUser?.username ? <span className="badge primary">Ref - {referredUser?.username}</span> : null}
                        
                    </div>
                    <div className="profle_section">
                        <div className="profile_card">
                            <div className="profile" >
                                {userDetails?.avatarPath ?
                                    <img src={userDetails?.avatarPath} className="pointer" alt="coinIcon" onClick={() => setOpen(true)} />
                                    :
                                    <img src={profile} alt="user profile" className="pointer" onClick={() => setOpen(true)} />
                                }
                                {/* <img src={userLogo} alt="user profile" onClick={() => setOpen(true)} /> */}
                            </div>
                            <div className="user_name">{userDetails?.username}</div>
                            <div className="user_coins">GAME COINS: {userDetails?.coin}</div>
                        </div>
                        {!userDetails?.isUserNameUpdated ? <div className="username_content_box">
                            You have yet to choose a username. To do so click
                            <button onClick={() => setUmodal(true)}>HERE</button>
                        </div>
                            :
                            null}
                        <div className="score_card_wrapper">
                            <div className="score_card">
                                <Link to="/favourited">
                                    <p>Favourited <span>{userDetails?.favourited?.length}</span></p>
                                </Link>
                                <div className="divider"></div>
                                <Link to="/favourites">
                                    <p>Favourites <span>{userDetails?.favourites?.length}</span></p>
                                </Link>
                                <div className="divider"></div>
                                <Link to="/playersReferred">
                                    <p>Players Referred <span>{userDetails?.referred?.length}</span></p>
                                </Link>
                            </div>
                        </div>
                        <div className="profile_banner">
                            <div className="profile_banner_content">
                                <div className="profile_banner_coins">
                                    <img alt={"icon"} src={gainCoinbg1}></img>
                                    <img alt={"icon"} src={gainCoin}></img>
                                    <img alt={"icon"} src={gainCoinbg2}></img>
                                </div>
                                <div className="title">Gain More Coins</div>
                                <div className="content">Complete your contact details by adding your email address to earn a further 2 coins</div>
                            </div>
                        </div>
                        <div className="accolades">
                            <div className="title">Accolades</div>
                            {
                                accoladesDetails.map((accolade, ind) => {
                                    return (
                                        <div className="board_box" key={ind}>
                                            <div className="bg_flash">
                                                <div className="bg_dark">
                                                    <span>{accolade.gameName}</span>
                                                    <span>{accolade.position}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="personal_details_form_section">
                            <div className="title d-flex justify-content-center">
                                <span>Personal Details</span>
                                <img className='pointer' src={pen} alt="edit logo" onClick={() => setIsInputEnable(false)} />
                            </div>
                            <div className="form_container_wrapper">
                                <div className="form_container">
                                    <div className="form_group">
                                        <div className="form_control">
                                            <label htmlFor="username" >User Name</label>
                                            <input type="text" placeholder="username" id="username" name='username' value={inputPersonalDetails?.username || ""} onChange={(e) => handleSearchChange(e)} disabled={userDetails?.isUserNameUpdated} />
                                            {isUsernameAvailable ? <div className='success-msg'>{userNameAvailbleMessage} </div>
                                                :
                                                <div className='error-msg'>{userNameAvailbleMessage} </div>
                                            }
                                        </div>
                                        <div className="form_control">
                                            <label htmlFor="email">Email</label>
                                            <input type="text" placeholder="alex@gmail.com" id="email" name='email' value={userDetails?.email || ""} disabled={true} />
                                        </div>
                                        {/* <div className="form_control">
                                            <label htmlFor="mobile">Phone Number</label>
                                            <input type="text" placeholder="alex@gmail.com" id="mobile" name='' onChange={(e) => handleChange(e)} />
                                        </div> */}
                                        <div className="form_control ">
                                            <label htmlFor="dob">Date of birth (Must be over 18)</label>
                                            <input type="text" placeholder="DD-MM-YYYY" id="dob" name='dob' value={inputPersonalDetails?.dob ? dob : ""} onChange={(e) => handleDobChange(e)} disabled={isInputEnable} />
                                            <div className='error-msg'>{dobError} </div>
                                        </div>
                                        <div className="form_control ">
                                            <label htmlFor="dob">Full Name</label>
                                            <input type="text" placeholder="full name" id="full name" name='full name' value={inputPersonalDetails?.fullName ? fullName : ""} onChange={(e) => handleNameChange(e)} disabled={isInputEnable} />
                                        </div>
                                    </div>
                                    <div className="radio_group">
                                        <div className="radio_title">Gender</div>
                                        <div className="item_group">
                                            <div className="radio_item">
                                                <input type="radio" name="gender" id="Male" value="Male" checked={gender === 'Male'} onChange={handleGenderChange} disabled={isInputEnable} />
                                                <label htmlFor="male">Male</label>
                                            </div>
                                            <div className="radio_item">
                                                <input type="radio" name="gender" id="Female" value="Female" checked={gender === 'Female'} onChange={handleGenderChange} disabled={isInputEnable} />
                                                <label htmlFor="female">Female</label>
                                            </div>
                                            <div className="radio_item">
                                                <input type="radio" name="gender" id="Other" value="Other" checked={gender === 'Other'} onChange={handleGenderChange} disabled={isInputEnable} />
                                                <label htmlFor="others">Others</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_address_container">
                                        <div className="address_title">
                                            <span>Address</span>
                                        </div>
                                        <div className="form_group ">
                                            <div className="form_control grow">
                                                <label htmlFor="addressLine1">Address line</label>
                                                <input type="text" placeholder="address line 1" id="addressLine1" name='addressLine1' value={inputPersonalDetails?.address?.addressLine1 || ""} onChange={(e) => handleAdressChange(e)} disabled={isInputEnable} />
                                            </div>
                                            {/* <div className="form_control">
                                                <label htmlFor="addressLine2">Address line_2</label>
                                                <input type="text" placeholder="address line 2" id="addressLine2" />
                                            </div> */}
                                            <div className="form_control grow">
                                                <label htmlFor="street">Street address</label>
                                                <input type="text" placeholder="street" id="street" name='streetAddress' value={inputPersonalDetails?.address?.streetAddress || ""} onChange={(e) => handleAdressChange(e)} disabled={isInputEnable} />
                                            </div>
                                            <div className="form_control">
                                                <label htmlFor="state">State</label>
                                                <input type="text" placeholder="state" id="state" name='state' value={inputPersonalDetails?.address?.state || ""} onChange={(e) => handleAdressChange(e)} disabled={isInputEnable} />
                                            </div>
                                            <div className="form_control">
                                                <label htmlFor="zip">Zip</label>
                                                <input type="text" placeholder="zip code" id="zip" name='zip' value={inputPersonalDetails?.address?.zip || ""} onChange={(e) => handleAdressChange(e)} disabled={isInputEnable} />
                                            </div>
                                        </div>
                                    </div>
                                    {isErrorWhileSaving ? <div className='error-msg text-center'>{errorWhileSaving} </div> : ""}
                                    {!isErrorWhileSaving ? <div className='success-msg text-center'>{successMessage} </div> : ""}
                                    <div className="form_button ">
                                        <button className={isInputEnable ? "btn btnDisabled " : "btn pointer"} onClick={(e) => handleSave(e, inputPersonalDetails)}>SAVE</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyProfile
