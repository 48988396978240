import React from 'react'

function TrendingCard({children}) {
    return (
      <div className="card_wrapper">
          {children}
      </div>
  )
}

export default TrendingCard