import React from 'react'
// import { AiOutlineCloseCircle } from "react-icons/ai";
// import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import LOGO from "../assets/images/png/logo.png";

function RecoverLayout({ element, title }) {
    return (
        <React.Fragment>
            <div className="recover_layout">
                    <div className="header" id="page-layoutHeader">
                        <div className="layout-header">
                            <div className="mainHeader">
                                <div className="container">
                                    <div className="mainHeader-container">
                                        <div className="mainHeader-logo">
                                            <Link to="/">
                                            <img src={LOGO} alt="Loading" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="header_recover">
                    <div className="container">
                        <div className="title">{title}</div>
                        {/* <div className="recover_btn_group">
                            <AiOutlineQuestionCircle className="icon" />
                            <Link to="/">
                                <AiOutlineCloseCircle className="icon icon_close" />
                            </Link>
                        </div> */}
                    </div>
                </div>
                {element}
            </div>
        </React.Fragment>
    )
}

export default RecoverLayout
