import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email: "",
    signupSuccess: false,
    errorMessage: "",
    emailError: false,
    otpError: false,
    passwordError: false,
    otpSuccess: false,
    resendOtp: false,
    fullName: "",
    password: "",
    confirmPassword: "",
    signUpfinish: false,
    loader: false,
    referralCode: ""
};

export const signUpSlice = createSlice({
    name: "signup",
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setSignupSuccess: (state, action) => {
            state.signupSuccess = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setEmailError: (state, action) => {
            state.emailError = action.payload;
        },
        setOtpError: (state, action) => {
            state.otpError = action.payload;
        },
        setPasswordError: (state, action) => {
            state.passwordError = action.payload;
        },
        setOtpSuccess: (state, action) => {
            state.otpSuccess = action.payload;
        },
        setResendOtp: (state, action) => {
            state.resendOtp = action.payload;
        },
        setFullName: (state, action) => {
            state.fullName = action.payload;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
        },
        setConfirmPassword: (state, action) => {
            state.confirmPassword = action.payload;
        },
        setSignUpfinish: (state, action) => {
            state.signUpfinish = action.payload;
        },
        setLoader: (state, action) => {
            state.loader = action.payload;
        },
        setReferralCode: (state, action) => {
            state.referralCode = action.payload;
        }
    }
});

export const {
    setEmail,
    setSignupSuccess,
    setErrorMessage,
    setOtpSuccess,
    setFullName,
    setPassword,
    setConfirmPassword,
    setSignUpfinish,
    setEmailError,
    setOtpError,
    setPasswordError,
    setLoader,
    setResendOtp,
    setReferralCode
} = signUpSlice.actions;

export default signUpSlice.reducer;