import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    favouritedData : [],
    errorMessage : false,
    favouritedLoading : false
};

export const favouritedSlice = createSlice({
    name: "favourited",
    initialState,
    reducers: {
        setFavouritedData: (state, action) => {
            state.favouritedData = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setFavouritedLoading: (state, action) => {
            state.favouritedLoading = action.payload;
        }
    }
});

export const {
    setFavouritedData,
    setErrorMessage,
    setFavouritedLoading
} = favouritedSlice.actions;


export default favouritedSlice.reducer;