import { configureStore } from "@reduxjs/toolkit";
import changePasswordSlice from "../components/changePassword/changePasswordSlice";
import headerSlice from "../components/header/headerSlice";
import referAndEarnSlice from "../components/refer&earn/referAndEarnSlice";
import profilSlice from "../components/myProfile/myProfileSlice";

import signSlice from "../components/signIn/signSlice";
import favouritesSlice from "../components/refer&earn/favouritesSlice";
import playersRefferedSlice from "../components/refer&earn/playersRefferedSlice";
import favouritedSlice from "../components/refer&earn/favouritedSlice";
import signUpSlice from "../components/signup/SignUpSlice";
import superBannerSlice from "../components/superBanner/superBannerSlice";
import prizesSlice from "../components/prizes/prizesSlice";
import homeSlice from "../components/home/homeSlice";
import faqSlice from "../components/faq/faqSlice";
import settingSlice from "../components/settings/SettingSlice";
import gamesFeedbackSlice from "../components/gamesFeedback/gamesFeedbackSlice";
import recoverPinSlice from "../components/recover/recoverPinSlice";
import termsAndConditionSlice from "../components/termsAndCondition/termsAndConditionSlice";
import coinsMeanPrizesSlice from "../components/coinsMeanPrizes/coinsMeanPrizesSlice";

export const store = configureStore({
    reducer: {
        header: headerSlice,
        signIn: signSlice,
        profile: profilSlice,
        favourites: favouritesSlice,
        playersReffered: playersRefferedSlice,
        favourited: favouritedSlice,
        changePassword: changePasswordSlice,
        signup: signUpSlice,
        superBanner: superBannerSlice,
        refer: referAndEarnSlice,
        prizes: prizesSlice,
        home: homeSlice,
        faqSlice: faqSlice,
        setting: settingSlice,
        gameFeedbackType: gamesFeedbackSlice,
        recoverPin: recoverPinSlice,
        termsAndCondition: termsAndConditionSlice,
        coinsMeanPrizes: coinsMeanPrizesSlice,
    }
})