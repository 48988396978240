import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ModalBox from "../common/ModalBox"
import { updateUserName, validateUserName } from './myProfileApi';
import { setIsUsernameAvailable, setUserNameAvailbleMessage } from './myProfileSlice';

function UserNameDialog(props) {
    const dispatch = useDispatch()

    let minUsernameLength = 3;
    let maxUsernamelength = 15
    const { isUsernameAvailable, userNameAvailbleMessage } = useSelector(state => state.profile)


    const [searchQuery, setSearchQuery] = useState("");
    const [isErrorUsername, setIsErrorUsername] = useState(true);
    let debounceTimer;

    const handleSearchChange = (event) => {
        dispatch(setIsUsernameAvailable(false))
        dispatch(setUserNameAvailbleMessage(""))
        const value = event.target.value;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            setSearchQuery(value);
        }, 700);
    };


    useEffect(() => {
        if (searchQuery.trim().length === 0) {
            setIsErrorUsername(true)
            dispatch(setIsUsernameAvailable(false))
            dispatch(setUserNameAvailbleMessage(""))
        }
        else if (searchQuery.length < minUsernameLength) {
            setIsErrorUsername(true)
            dispatch(setIsUsernameAvailable(false))
            dispatch(setUserNameAvailbleMessage("Usernname should be greater than 2 characters"))
        }
        else if (searchQuery.length > maxUsernamelength) {
            setIsErrorUsername(true)
            dispatch(setIsUsernameAvailable(false))
            dispatch(setUserNameAvailbleMessage("Usernname should be less than 16 characters"))
        }
        else {
            setIsErrorUsername(false)
            dispatch(validateUserName(searchQuery))
        }
    }, [searchQuery, dispatch, minUsernameLength, maxUsernamelength]);
    
    const handleSubmit = (username) => {
        if (!isErrorUsername && isUsernameAvailable) {
            dispatch(updateUserName(username))
        }
    }




    return (
        <ModalBox dOpen={props.open} dClose={props.close} isClosable={false} >
            <div className="myprofile_username_modal">
                <div className="title">
                    Choose your unique username!
                </div>
                <div className="content">
                    Your username will be displayed on game leaderboards and will not be able to be changed, so choose carefully!

                    <div className="light">
                        NOTE: In the event of a username being deemed unsuitable we reserve the right to close the account and withold any prizes won.
                    </div>
                </div>
                <div className="form_group">
                    <div className="label">username</div>
                    <input type="text" className="form_input" placeholder="Enter username" onChange={(e) => handleSearchChange(e)} />
                    <div className="form_error">
                        {isUsernameAvailable ? <div className='success-msg'>{userNameAvailbleMessage} </div>
                            :
                            <div className='error-msg'>{userNameAvailbleMessage} </div>
                        }
                        <span className="username_length">{`${searchQuery.length}/${maxUsernamelength}`}</span>
                    </div>
                </div>
                <button className="btn btn-large" onClick={() => handleSubmit(searchQuery)}>
                    SAVE
                </button>
            </div>
        </ModalBox>
    )
}

export default UserNameDialog
