import React, { useState } from 'react'

function Accordion({ title, content }) {

    const [isAccordionActive, setisAccordionActive] = useState(false)


    return (
        <React.Fragment>
            <div className={isAccordionActive ? "cric-accordion " : "cric-accordion collapsed"} >
                <div className="cric-Accordion-header" onClick={() => setisAccordionActive(!isAccordionActive)}>
                    <div className="cric-sidePanelList-item-content">
                        {title}
                    </div>
                </div>
                <div className="cric-accordion-content">
                    <div className="cric-Item">{content}</div>
                </div>
            </div>
        </React.Fragment>


    )
}

export default Accordion