import React from 'react'
import starIcon from "../../assets/images/svg/starIcon.svg";
import grayStarIcon from "../../assets/images/svg/star-faded.svg";
import { ContentLoader } from "../common/Loader";
import { putFavourite } from './favouritesApi';
import { useDispatch } from 'react-redux';
import dummyAvtar from '../../assets/images/png/Avatar.png';

function StackBox(props) {
  const dispatch = useDispatch();
  return (
    <div>
      <div className="stackbox_container">
        <div className="page_title">
          {props.title} <span>{props.count}</span>
        </div>
        {props?.dataLoading ? <ContentLoader /> :
          <>
            {!props.noData ? <div className="referred_players_container">
              {
                props?.data?.map((item, index) => {
                  return (
                    <div className="flash_container" key={index}>
                      < div className="referred_player_item" key={item.id}>
                        <div className="player_id">{index + 1}</div>
                        <div className="player_avatar">
                          {item?.avatarPath?.length > 1 ? <img className="player_avatar_img" src={item.avatarPath} alt={"img"} /> :
                          <img className="player_avatar_img" src={dummyAvtar} alt={"img"} />}                      
                        </div>
                        <div className="player_name">{item?.username}</div>
                        {/* <div className="referred_count">{item.referred !== 0 ? item.referred : "-"}</div>
                    <div className="amount">{item.amount !== 0 ? `INR ${item.amount}` : "-"}</div> */}
                        {props.title === "Favourited" ? (
                          <>
                            {item?.favourite ? <img className="star_icon_img pointer" src={starIcon} alt={"fav"} onClick={()=>dispatch(putFavourite(item.id))}/>
                              :
                              <img className="star_icon_img pointer" src={grayStarIcon} alt={"fav"} onClick={()=>dispatch(putFavourite(item.id))}/>
                            }
                          </>)
                          : props.title === "Favourites" ? <img className="star_icon_img pointer" src={starIcon} alt={"fav"} onClick={()=>dispatch(putFavourite(item.id))}/> : null}
                      </div>
                    </div>
                  )
                })
              }
            </div> : <div className="text-center">{props.noDataMsg}</div>}
          </>}

      </div>
    </div>
  )
}

export default StackBox
