import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email: "",
    referSuccess: false,
    errorMessage: "",
    loader: false
};

export const referAndEarnSlice = createSlice({
    name: "refer",
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setReferSuccess: (state, action) => {
            state.referSuccess = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setLoader: (state, action) => {
            state.loader = action.payload;
        }
    }
});

export const {
    setEmail,
    setReferSuccess,
    setErrorMessage,
    setLoader
} = referAndEarnSlice.actions;

export default referAndEarnSlice.reducer;