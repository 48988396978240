import { endpoints } from "../../api/ApiEndPoints";
import { postRequest } from "../../services/apiCaller";
import { setErrorMessage, setLoader, setReferSuccess } from "./referAndEarnSlice";

export const referUser = (email) => (dispatch) => {
    let regUrl = endpoints.refer;
    const payload = {
        email: [`${email}`]
    }
    dispatch(setLoader(true));
    postRequest(regUrl, payload)
        .then((res) => {
            if (res?.status === 200) {
                dispatch(setReferSuccess(true));
                dispatch(setErrorMessage(""));
                dispatch(setLoader(false));
            } else {
                dispatch(setReferSuccess(false));
                dispatch(setErrorMessage(res?.response?.data?.message));
                dispatch(setLoader(false));
            }
        })
        .catch((err) => {
            dispatch(setReferSuccess(false));
            dispatch(setErrorMessage(err?.response?.data?.message));
            dispatch(setLoader(false));
        })
};