import React from 'react'
import WinnerCard from "./WinnerCard"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import {  useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFromLocalStorage } from '../../services/helper/Index';


function Winner(props) {
    const { WinnersData, navigateToResult } = props

    const { isSignInSuccess } = useSelector((state) => state.signIn);
    const token = getFromLocalStorage("gk")
    const navigate = useNavigate()

    const navigateToResultBoard = () => {
        console.log("I am jGChjfs")
        if (token && isSignInSuccess) {
            navigate(navigateToResult)
        } else {
            navigate("/signin")
        }
    }

    return (
        <div className="winner_board_wrapper">
            <div className="winner_board_title">
                <span>Winners</span>
                <span className='pointer' onClick={() => navigateToResultBoard()} >All Results</span>
            </div>
            <div className="winner_card_section">
                <Splide
                    options={{
                        // type: "loop",
                        perPage: 4,
                        // focus: "center",
                        gap: 10,
                        pagination: false,
                        arrows: WinnersData.length > 1 ? true : false,
                        clones: false,
                        // perMove: 1,
                        breakpoints: {
                            1273: {
                                perPage: 3,
                                gap: 0,
                            },
                            1100: {
                                perPage: 2,
                                gap: 0,
                            },
                            656: {
                                perPage: 1,
                                gap: 0
                            },
                        },
                    }}
                >
                    {WinnersData && WinnersData.length > 0 &&
                        WinnersData.map((player) => {
                            return (
                                <SplideSlide key={player.id}>
                                    <WinnerCard playerData={player} />
                                </SplideSlide>
                            )
                        })
                    }
                </Splide>
            </div>
        </div>
    )
}

export default Winner
