import { endpoints } from "../../api/ApiEndPoints";
import { setIsSignInSuccess, setSignErrorMessage, setSignInSuccessMessage, setUserDetails, setSignInLoading,setReferredUser } from "./signSlice";
import { removeFromLocalStorage, setToLocalStorage } from "../../services/helper/Index";
import { putRequest, getRequest } from "../../services/apiCaller";
// import { gethomeBannerImages } from "../superBanner/superBannerApi";
import { setHomeBannerImages } from "../superBanner/superBannerSlice";

const emailRegX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;


export const validateEmail = (email) => {
  if (emailRegX.test(email)) return true
  return false
}



export const getuserSignin = (email, password, navigate) => (dispatch) => {
  let loginUrl = endpoints.login;
  let body = {
    email: email,
    password: password,
  };
  dispatch(setSignInLoading(true));
  putRequest(loginUrl, { ...body })
    .then((res) => {
      if (res.status === 200) {
        dispatch(setSignInLoading(false));
        dispatch(setIsSignInSuccess(true))
        dispatch(setHomeBannerImages([]))
        dispatch(setSignInSuccessMessage(res.data.message))
        dispatch(setUserDetails(res.data.user))
        setToLocalStorage("gk", res.data.token)
        // dispatch(gethomeBannerImages());  
        dispatch(setSignErrorMessage(""))
        navigate("/")
        removeFromLocalStorage("ak")
      } else {
        dispatch(setSignInLoading(false));
        dispatch(setIsSignInSuccess(false))
        dispatch(setSignErrorMessage(res.response.data.message))
        dispatch(setSignInSuccessMessage(""))
      }
    })
    .catch((err) => {
      dispatch(setSignInLoading(false));
      const errorResponse = err.response
      dispatch(setIsSignInSuccess(false))
      dispatch(setSignErrorMessage(errorResponse.data.message))
      dispatch(setSignInSuccessMessage(""))
    })
};

export const getReferredByUser = () => (dispatch) => {
  let referredByUserURL = endpoints.referredByUser;
  getRequest(referredByUserURL, true)
    .then((res) => {
      if (res?.status === 200) {
        dispatch(setReferredUser(res.data.invitedByPlayer));
      }
      
    })
    .catch((err) => {
     console.log(err);
    })
}
