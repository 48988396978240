import React from "react";
import { BsCheck } from "react-icons/bs";
import { useSelector, connect } from "react-redux";
import BackButton from "../common/BackButton";
import LOGO from "../../assets/images/png/logo.png";
import { Link } from "react-router-dom";

function ResetPin(props) {
    const emailIDStart = props?.email.slice(0, 2);
    return (
        <>
            <div className="recover_layout">
                <div className="header" id="page-layoutHeader">
                    <div className="layout-header">
                        <div className="mainHeader">
                            <div className="container">
                                <div className="mainHeader-container">
                                    <div className="mainHeader-logo">
                                        <Link to="/">
                                            <img src={LOGO} alt="Loading" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header_recover">
                    <div className="container">
                        <BackButton />
                        <div className="title">Recover Pin</div>
                        {/* <div className="recover_btn_group">
                            <AiOutlineQuestionCircle className="icon" />
                            <Link to="/">
                                <AiOutlineCloseCircle className="icon icon_close" />
                            </Link>
                        </div> */}
                    </div>
                </div>
                <div className="confirmationPin_wrapper">
                    <div className="container">
                        <div className="recover_details_body">
                            <div className="mail_response">
                                <BsCheck className="check_icon" />
                                <p>We've sent an email. Please check your Inbox.</p>
                            </div>
                            <div className="content_wrapper">
                                <div className="content_container">
                                    <p>We've sent an email to {emailIDStart}**@gmail.com with a link to reset your PIN and gain access to your account again.</p>
                                    <p>Please check your spam folder if you haven't received the email.</p>
                                    <p>If you don't recognise the partially shown email address or you don't have access to it any  more, please contact us via email at <a href='mailto:support@cricctt.com' class='direct-link'>support@cricctt.com</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function mapStateToProps(state) {
    return {
        email: state?.recoverPin?.email
    };
}
export default connect(mapStateToProps)(ResetPin)