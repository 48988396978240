export const setToLocalStorage = (key, Data) => {
  let localStorageValue = btoa(Data);
  localStorage.setItem(key, localStorageValue);
};

export const getFromLocalStorage = (key) => {
  let getLocalStorageValue = "";
  let v = localStorage.getItem(key);
  if (!v) {
    v = "";
  }
  getLocalStorageValue = atob(v);
  return getLocalStorageValue;
};

export const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key)
}

let regexp = /android|iphone|kindle|ipad/i;
export const isMobileDevice = regexp.test(navigator.userAgent);

export const cleanObject = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] == null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const dateOrdinal = (d) =>
  d +
  (31 === d || 21 === d || 1 === d
    ? "st"
    : 22 === d || 2 === d
      ? "nd"
      : 23 === d || 3 === d
        ? "rd"
        : "th");


export const getLocalString = (value) => {
      return value.toLocaleString();
}