import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    recoverPinResponseSuccess: "",
    recoverPinResponseError: "",
    updatePinResponseSuccess: "",
    updatePinResponseError: "",
    checkPinError: "",
    checkPinSuccess: "",
    email: ""
};

export const recoverPinSlice = createSlice({
    name: "recoverPin",
    initialState,
    reducers: {
        setRecoverPinResponseSuccess: (state, action) => {
            state.recoverPinResponseSuccess = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setRecoverPinResponseError: (state, action) => {
            state.recoverPinResponseError = action.payload;
        },
        setUpdatePinResponseSuccess: (state, action) => {
            state.updatePinResponseSuccess = action.payload;
        },
        setUpdatePinResponseError: (state, action) => {
            state.updatePinResponseError = action.payload;
        },
        setCheckPinError: (state, action) => {
            state.checkPinError = action.payload;
        },
        setCheckPinSuccess: (state, action) => {
            state.checkPinSuccess = action.payload;
        }
    }
});

export const { setRecoverPinResponseSuccess,
    setRecoverPinResponseError,
    setUpdatePinResponseSuccess,
    setUpdatePinResponseError,
    setCheckPinError,
    setCheckPinSuccess,
    setEmail } = recoverPinSlice.actions;

export default recoverPinSlice.reducer;