import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useSelector } from "react-redux";
import BannerText from "../config/BannerText.json";

function SuperBanner() {
  const bannerImages = useSelector(state => state.superBanner.homeBannerImages)

  return (
    <React.Fragment>
      {bannerImages.web && bannerImages.web.length > 0 && bannerImages.mobile && bannerImages.mobile.length > 0 &&
        <div className="container">
          <div className="banner_wrapper show-desktop hide-mobile">
            <Splide
              options={{
                type: "loop",
                perPage: 1,
                arrows: false,
                gap: 5
              }}
            >
              {bannerImages?.web?.map((img, index) => {
                return (
                  <SplideSlide key={index}>
                    <div className="homepage_banner ">
                      <div className="banner_container">
                        <img src={img} alt="banner" className="banner" />
                      </div>
                    </div>
                  </SplideSlide>
                )
              })}
            </Splide>
          </div>
          <div className="banner_wrapper show-mobile">
            <Splide
              options={{
                type: "loop",
                perPage: 1,
                arrows: false,
                gap: 5
              }}
            >
              {
                bannerImages?.mobile?.map((img, index) => {
                  return (
                    <SplideSlide key={index}>
                      <div className="homepage_banner">
                        <div className="banner_container">
                          <img src={img} alt="banner" className="banner" />
                        </div>
                      </div>
                    </SplideSlide>
                  )
                })
              }
            </Splide>
          </div>
        </div>}
    </React.Fragment>
  )
}

export default SuperBanner