import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Vector from "../../assets/images/png/Vector.png";
import Facebook from "../../assets/images/png/facebook.png";
import Telegram from "../../assets/images/png/telegram.png";
import { connect, useDispatch } from "react-redux";
import { submitGamefeedBack } from './gamesFeedbackApi';
import { ButtonLoader } from "../common/Loader";
import { setFeedbackSubmitSuccess, setfeedbackError, setFeedbackErrorMessage } from './gamesFeedbackSlice';
import FeedbackSubmit from './FeedbackSubmit';

function GamesFeedback(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        return () => {
            setDescriptionError(false)
            dispatch(setFeedbackSubmitSuccess(false));
            dispatch(setfeedbackError(false))
            dispatch(setFeedbackErrorMessage(""));
        };
    }, [])
    const [feedbackType, setFeedbackType] = useState("A Technical issue");
    const dispatch = useDispatch();
    const [feedbackDescription, setFeedbackDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState(false);
    const handleDescriptionChange = (e) => {
        setDescriptionError(false)
        dispatch(setFeedbackSubmitSuccess(false));
        dispatch(setfeedbackError(false))
        dispatch(setFeedbackErrorMessage(""));
        setFeedbackDescription(e.target.value);
    };

    const submitGamefeedBackOnclick = () => {
        if (feedbackDescription?.length > 0) {
            dispatch(submitGamefeedBack(feedbackType, feedbackDescription))
        }
        else {
            setDescriptionError(true)
        }
    }

    useEffect(() => {
        if (props?.feedbackSubmitSuccess) {
            setFeedbackType("A Technical issue");
            setFeedbackDescription("");
        }
    }, [props?.feedbackSubmitSuccess]);

    return (
        <React.Fragment>
            <div className="games_Feedback_wrapper">
                <div className="container">
                    <div className="games_Feedback_container">
                        <div className="games_Feedback_head">
                            <div className="title pagetitle brand_secondary">Games Feedback</div>
                        </div>
                        <div className="content">
                            <p>Your thoughts and feedback are incredibly important to us and we'd really like to know of your opinions and of any issues you may encounter so that we can improve our app so that it provides the most fun possible! </p>
                            <p>Please provide your comments below.</p>
                            <p>If your need customer support then please contact us on one of the methods below.</p>
                            <p>Before you contact us for assistance, please take note of our
                                <Link to="/faqs">
                                    <span> FAQs section</span>
                                </Link></p>
                            <p>If you would like to contact us, you can email support@cricctt.com</p>
                        </div>
                        <div className="mainbox_content">
                            <a href="https://twitter.com/cricctt" target={"_blank"} rel="noreferrer">
                                <div className="box_content">
                                    <img src={Vector} alt={"social Icon"} />
                                    <div className="social_title">@CRICCTT</div>
                                </div>
                            </a>
                            <a href="https://www.facebook.com/cricctt" target={"_blank"} rel="noreferrer">
                                <div className="box_content"> <img src={Facebook} alt={"social Icon"} />
                                    <div className="social_title">MESSAGE US ON FACEBOOK</div>
                                </div>
                            </a>
                            <a href="https://telegram.me/cricctt" target={"_blank"} rel="noreferrer">
                                <div className="box_content"> <img src={Telegram} alt={"social Icon"} />
                                    <div className="social_title">@CRICCTT</div>
                                </div>
                            </a>
                        </div>
                        <div className="games_Accordion">
                            <div className="games_Accordion_head">What would you like to tell us about today</div>
                            <div className="games_Accordion_Content">
                                <select
                                    className="feedback_dropdown"
                                    id="gamefeedback"
                                    onChange={(e) => setFeedbackType(e.target.value)}
                                    value={feedbackType}
                                >
                                    <option value="A Technical issue" >A Technical issue</option>
                                    <option value="Some thing That I like">Some thing That I like</option>
                                    <option value="A Suggested Improvement">A Suggested Improvement</option>
                                    <option value="A Comment On Our Games">A Comment On Our Games</option>
                                    <option value="Something Else">Something Else </option>
                                </select>
                            </div>


                        </div>
                        <div className="feedback_container">
                            <div className="feedback_header">Please give us your feedback </div>
                            <textarea className="feedback_input" rows={10} value={feedbackDescription} onChange={(e) => handleDescriptionChange(e)}></textarea>
                        </div>
                        {descriptionError ?
                            <div className="errormessage">Please add some description for your feedback</div> :
                            props?.feedbackError ? <div className="errormessage">{props?.feedBackErrorMessage}</div> : null
                        }
                        {
                            props?.feedbackSubmitSuccess &&
                            <FeedbackSubmit open={props?.feedbackSubmitSuccess} close={() => dispatch(setFeedbackSubmitSuccess(false))} closable={false} />
                        }
                        <div className="btn-container">
                            <button className='btn btnMedium' onClick={() => submitGamefeedBackOnclick()}>
                                {props?.feedbacksSubmitLoading ? <ButtonLoader /> : "Submit Feedback"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        feedbackSubmitSuccess: state.gameFeedbackType.feedbackSubmitSuccess,
        feedbackError: state.gameFeedbackType.feedbackError,
        feedbacksSubmitLoading: state.gameFeedbackType.feedbacksSubmitLoading,
        feedBackErrorMessage: state.gameFeedbackType.feedBackErrorMessage,
    };
}
export default connect(mapStateToProps)(GamesFeedback)