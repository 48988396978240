var beEndPoint = process.env.REACT_APP_BE_END_POINT;

export const endpoints = {
    login: beEndPoint + "authentication/login",
    userInfo: beEndPoint + "user/info",
    changeUserInfo: beEndPoint + "user/my/change-info",
    userNameValidate: beEndPoint + "user/validateUsername",
    favourites: beEndPoint + "user/favourites",
    playerReffered: beEndPoint + "user/refered",
    favourited: beEndPoint + "user/favourited",
    changepassword: beEndPoint + "user/my/change-password",
    register: beEndPoint + "authentication/send-verification-code",
    resendOtp: beEndPoint + "authentication/resend-verification-code",
    otpGenrate: beEndPoint + "authentication/confirm-code",
    finish_registration: beEndPoint + "authentication/finish-registration",
    beforeLogin_homePage_bannerImages: beEndPoint + "authentication/bannerImages",
    afterLogin_homePage_bannerImages: beEndPoint + "user/bannerImages",
    refer: beEndPoint + "user/invite",
    logoutUser: beEndPoint + "user/logout",
    updateUser: beEndPoint + "user/my/change-info",
    avatarList: beEndPoint + "user/avatarImages",
    prizes: beEndPoint + "user/prizes",
    accolades: beEndPoint + "user/accolades",
    beforeLoginLeaderBoard: beEndPoint + "authentication/home",
    afterLoginLeaderBoard: beEndPoint + "user/home",
    faq: beEndPoint + "/authentication/faq",
    gametext: "https://crictt-stg-cdn.sportsit-tech.net/Static.json",
    termsAndConditionBeforeLogin: "https://crictt-stg-cdn.sportsit-tech.net/TermsAndConditionsBeforeLogin.json",
    termsAndConditionAfterLogin: "https://crictt-stg-cdn.sportsit-tech.net/TermsAndConditions.json",
    faqs: "https://crictt-stg-cdn.sportsit-tech.net/FAQS.json",
    faqBeforeLogin: "https://crictt-stg-cdn.sportsit-tech.net/FAQSBeforeLogin.json",
    coinMeansPrizes: "https://crictt-stg-cdn.sportsit-tech.net/coinsMeanPrizes.json",
    gameFeedback: beEndPoint + "user/my/feedBack",
    recoverPin: beEndPoint + "authentication/send-reset-code",
    checkPin: beEndPoint + "authentication/check-reset-code",
    updatePin: beEndPoint + "authentication/reset-password",
    referredByUser: beEndPoint + "user/invitedBy",
}