import React, { useState, useEffect } from 'react'
import Accordion from '../common/Accordion'
import GainCoinsModal from './GainCoinsModal'
import howToGainCoinsImage from "../../assets/images/png/howToGainCoinsImage.png";
import { useSelector,useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { getReferredByUser } from '../signIn/signInApi';

function HowToGainCoins() {
    const [coinsModal, setCoinsmodal] = useState(false)
    const { userDetails,referredUser } = useSelector(state => state.signIn)
    const dispatch = useDispatch()

    function AccOneContent() {
        return (
            <div className="accordion_content" >
                <div className="accordion_sectionOne">
                    <div className="content">
                        <p>Coins are at the heart of all of the games available to you on our Pari Match Cricket Play app.</p>
                        <p>Though the app is free to play and we give you ample coins to play our tournaments for the first few weeks, you will need to accrue more to continue playing in relatively simple ways.</p>
                    </div >
                </div >
            </div>
        );
    };

    function AccTwoContent() {
        return (
            <div className="accordion_content" >
                <div className="accordion_sectionOne">
                    <div className="content">
                        <div className="title">FREQUENCY</div>
                        <p>The more times that you login the more points you get at the end of the week.</p>
                        <div>Login 7 days between Saturday - Friday <span>&emsp; 8 Coins</span></div>
                        <div>Login 6 days between Saturday - Friday <span>&emsp; 6 Coins</span></div>
                        <p>Login 5 days between Saturday - Friday <span>&emsp; 4 Coins</span></p>
                        <p>To confirm how many days you have logged in this game week, <span className='pointer' onClick={() => setCoinsmodal(true)}> CLICK HERE </span></p>
                    </div>
                </div>
                <div className="accordion_sectionOne">
                    <div className="content">
                        <div className="title">Completion of Profile Details</div>
                        <p>It couldn't be easier, complete all of your personal details and we will reward you with 4 Coins. You can do this by visiting your profile.</p>
                    </div>
                </div>
                <div className="accordion_sectionOne">
                    <div className="content">
                        <div className="title">Friend Referrals</div>
                        <p>The benefits of referring your friends are three-fold</p>
                        <ul>
                            <li>&nbsp;You'll get kudos from your friends for introducing them to our great games where you can compete against them</li>
                            <li>&nbsp;You win when they win! If one of the friends that you have referred goes on to win one of our top first placed prizes then you will win up to 10% of the prize amount that they win</li>
                            <li>&nbsp;You win when they win! If one of the friends that you have referred goes on to win one of our top first placed prizes then you will win up to 10% of the prize amount that they win</li>
                        </ul>
                    </div>
                </div>
                <div className="accordion_sectionOne">
                    <div className="content">
                        <p><span> <Link to="/referandearn">REFER FRIENDS HERE </Link></span></p>
                        <div className="title">Play Our Daily Virtual Catch The Trend Game</div>
                        <p>Enjoy the fun of our virtual cricket game and finish high on the leaderboard on any given day to earn more coins.</p>
                    </div>
                </div>
                {/* <div className="accordion_sectionOne">
                    <div className="content">
                        <div className="title">Become A VIP</div>
                        <p>Download our great real money games app to win even bigger prizes and automatically game VIP Status and enjoy all the points you'll need to play our games through the tournaments.</p>
                        <p><span>SEE DETAILS HERE</span></p>
                    </div>
                </div> */}
            </div >
        );
    };

    useEffect(() => {
        dispatch(getReferredByUser())
    }, [dispatch])

    return (
        <React.Fragment>
            {
                <GainCoinsModal open={coinsModal} close={() => setCoinsmodal(false)} />
            }
            <div className="gain_coins_wrapper">
                <div className="container">
                    <div className="gain_coin_container">
                        <div className='gain_coins_head'>
                            <div className='gain_coins_title title_for_mobile'>How To Gain Coins</div>
                            {referredUser?.username ? <div className='gain_coins_ref'>Ref - {referredUser?.username}</div> : null}
                        </div>
                        <div className="gain_coin_bannerHeader">
                            <div className="gain_coin_banner">
                                <img alt='banner' height={350} src={howToGainCoinsImage}></img>
                            </div>
                            <div className="gain_coin_banner_text">
                                <div className="gain_coinBanner_textHeader">
                                    <h2>How To Gain</h2>
                                    <h1>Coins</h1>
                                </div>
                            </div>
                        </div>
                        <div className='HowTogain_Accordion'>
                            <Accordion title={"How to Gain Coins"} content={<AccOneContent />} />
                            <Accordion title={"Ways to Gain Coins"} content={<AccTwoContent />} />
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HowToGainCoins