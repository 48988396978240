import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    faqResponse: ""
};

export const faqSlice = createSlice({
    name: "faq",
    initialState,
    reducers: {
        setFaqResponse: (state, action) => {
            state.faqResponse = action.payload;
        }
    }
});

export const { setFaqResponse } = faqSlice.actions;

export default faqSlice.reducer;