import { endpoints } from "../../api/ApiEndPoints";
import { getRequest } from "../../services/apiCaller";
import { setError, setCoinsMeanPrizesData } from "./coinsMeanPrizesSlice";

export const getCoinsMeanPrizesData = () => (dispatch) => {
    let coinsMeanPrizesUrl = endpoints.coinMeansPrizes;
    dispatch(setCoinsMeanPrizesData([]))
    getRequest(coinsMeanPrizesUrl, false)
    .then((res) => {
        if(res.status === 200){
            dispatch(setCoinsMeanPrizesData(res.data));
        }
    })
    .catch((err) => {
        dispatch(setError(err));
    })
};