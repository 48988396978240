import React, { useEffect, useState } from 'react';
import ModalBox from "../common/ModalBox";
import { BiTrashAlt } from "react-icons/bi";
import { RiAddFill } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import submitIcon from "../../assets/images/png/submitIcon.png";
import closeIcon from "../../assets/images/png/closeIcon.png";
import { Link, Navigate } from 'react-router-dom';

function FeedbackSubmit(props) {
    const [id, setId] = useState(0);
    const dispatch = useDispatch();




    return (
        <ModalBox dOpen={props.open !== ""} dClose={props.close} isClosable={props.closable} >
            <div className="games_Feedback_popup">
                <div className='popup_content'>
                <img className="submit_icon" src={submitIcon} alt={"img"} />
                <div className="title">
                    Thank you for your feedback
                </div>
                 <div className="content">
                    Your comments has been successfully <br/>submitted
                </div>
                <div className='btn-wrapper'>
                    <Link to="/">
                    <button className='btn btnMedium' >
                        Continue
                    </button>
                    </Link>
                </div>
                </div>
            </div>
        </ModalBox>
    )
};

export default FeedbackSubmit
