import React, { useEffect, useState } from 'react';
import Accordion from '../common/Accordion';
import InviteFriends from "./InviteFriends";
import ReferandearnImage from "../../assets/images/png/ReferandearnImage.png";
// import referNEarnBtn from "../../assets/images/png/referNEarnBtn.png";
import referNEarnDesktop from "../../assets/images/png/referNEarnDesktop.png";

function ReferAndEarn() {
    const [inviteType, setInviteType] = useState(false);

    function inviteHandler(type) {
        setInviteType(type);
    };
    
    useEffect(() => {
        window.scrollTo(0, 0);
    })

    function AccOneContent() {
        return (
            <div className="accordion_content" >
                <div className="accordion_sectionOne">
                    <div className="content">
                        <p>Our Refer and Earn program is excellent!</p>
                        <p>You can send your friends a unique URL that you can then send to your friends so that when they register they are tracked to you.</p>
                        <p>When your friends sign up for the first time directly from your referral link, you will automatically gain 1 additional coin per player registered.</p>
                    </div >
                </div >
            </div>
        );
    };

    function AccTwoContent() {
        return (
            <div className="accordion_content" >
                <div className="accordion_sectionOne">
                    <div className="content">
                        <p>When your friend signs up to our app for the first time using your referral link, you will automatically gain 1 Coin for each user that plays any game.</p> <br />
                        <p>More than that though, not only will they be super appreciative of being able to compete in our great free to play games, you will gain a 10% prize bonus of any first place position that they gain!</p> <br />
                        <p>There are 4 ways in which to refer a friend to the game.</p>
                        <ol>
                            <li>Get your link by clicking HERE which you can then share with friends one platform of your choice. If they register to this link, they will be a player tagged to you. </li> <br />
                            <li>You can automatically send an email to your friends with your referral link by clicking <button className="link_button" onClick={() => inviteHandler(true)}>HERE</button> and entering their email address. </li> <br />
                            {/* <li>You can automatically send an SMS to your friends with your referral link by clicking <button className="link_button" onClick={() => inviteHandler("mobile")}>HERE</button>. </li> <br/> */}
                            <li>You can share each weeks selections and encourage your friends to play by sharing the game link exclusively to you This will generate a BTag for the player that they can share with friends anywhere of their choice. </li>
                        </ol>
                    </div>
                </div>

            </div >
        );
    };

    function AccThirdContent() {
        return (
            <div className="accordion_content" >
                <div className="accordion_sectionOne">
                    <div className="content">
                        <p>If You Are Planning to signup on HIT6, you can check out for some important information below</p>
                        <a href="https://telegram.me/cricctt" target={"_blank"} rel="noreferrer"><button className="telegram_refer_button"><img src={referNEarnDesktop} alt="Refer and Earn Banner" /></button></a>
                    </div>
                </div>

            </div >
        );
    };

    return (
        <React.Fragment>
            {
                inviteType &&
                <InviteFriends open={inviteType} close={() => setInviteType(false)} closable={true} />
            }
            <div className="gain_coins_wrapper">
                <div className="container">
                    <div className="gain_coin_container">
                        <div className='gain_coins_head'>
                            <div className='gain_coins_title'>Refer and Earn</div>
                        </div>
                        <div className="gain_coin_bannerHeader">
                            <div className="gain_coin_banner">
                                <img alt='banner' height={350} src={ReferandearnImage}></img>
                            </div>
                            <div className="gain_coin_banner_text">
                                <div className="gain_coinBanner_textHeader">
                                    <div className="refer"><h3>Refer</h3></div>
                                    <div className="and"><h3>And</h3></div>
                                    <div className="Earn"><h3>Earn</h3></div>
                                </div>
                            </div>
                        </div>
                        <div className='HowTogain_Accordion'>
                            <div className="bg_flash">
                                <Accordion title={"Refer and Earn"} content={<AccOneContent />} />
                                <Accordion title={"How to verify account?"} content={<AccTwoContent />} />
                                <Accordion title={"How to Register and Login on HIT6?"} content={<AccThirdContent />} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default ReferAndEarn