import React from 'react';
import { Link } from "react-router-dom";
import LOGO from "../../assets/images/png/logo.png";
import BackButton from '../common/BackButton';

function Details() {
    return (
        <>
            <div className="recover_layout">
                <div className="header" id="page-layoutHeader">
                    <div className="layout-header">
                        <div className="mainHeader">
                            <div className="container">
                                <div className="mainHeader-container">
                                    <div className="mainHeader-logo">
                                        <Link to="/">
                                            <img src={LOGO} alt="Loading" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header_recover">
                    <div className="container">
                        <BackButton />
                        <div className="title">Recover Pin</div>
                    </div>
                </div>
                <div className="container">
                    <Link to="/recover-pin">
                        <div className="recover_details_body">
                            <div className="flash_container_outer">
                                <div className="flash_container_inner">
                                    I have forgotten my PIN
                                    <span className="icon"></span>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Details
