import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { connect } from "react-redux";
import StackBox from "./StackBox";
import { getFavourites } from "./favouritesApi";

const Favourites = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getFavourites());
    }, [dispatch]);
    return (
        <>
            <div className="container">
                <StackBox title={"Favourites"} count={props?.favouritesData?.length} data={props?.favouritesData} noData={props.errorMessage} dataLoading={props.favouritesLoading} noDataMsg={"Currently no Favourites Available"}/>
            </div>
        </>
    )
}
function mapStateToProps(state) {
    return {
        favouritesData: state.favourites.favouritesData,
        errorMessage: state.favourites.errorMessage,
        favouritesLoading: state.favourites.favouritesLoading,
    };
}
export default connect(mapStateToProps)(Favourites);
