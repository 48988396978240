import { endpoints } from "../../api/ApiEndPoints";
import { getRequest, postRequest, putRequest } from "../../services/apiCaller/index"
import { cleanObject } from "../../services/helper/Index";
import { setUserDetails } from "../signIn/signSlice";
import { setAccoladesDetails, setAllAvatarImages, setErrorWhileSaving, setIsErrorWhileSaving, setIsUsernameAvailable, setSuccessMessage, setUserNameAvailbleMessage } from "./myProfileSlice";



export const isUserNameChanged = (latestuserName, getState) => {
    const { signIn } = getState()
    const oldUsername = signIn?.userDetails?.username
    if (oldUsername === latestuserName) {
        return false
    } else {
        return true
    }
}
export const checkEmptyObj = (obj) => {
    for (var propName in obj) {
      if (
        obj[propName] == null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    return obj;
  };

export const isEighteenPlus = (dob) => {
    const [day, month, year] = dob.split("-");
    const birthDate = new Date(`${year}-${month}-${day}`);
    const diffInMs = Date.now() - birthDate.getTime();
    const ageInYears = diffInMs / 1000 / 60 / 60 / 24 / 365;
    return ageInYears >= 18;
}

function convertDateFormat(dateString) {
    const [day, month, year] = dateString.split('-');
    const newDateString = `${month}-${day}-${year}`;
    return newDateString;
}




export const validateUserName = (username) => (dispatch) => {
    let uservalidateUrl = endpoints.userNameValidate;
    let body = {
        username: username,
    };

    postRequest(uservalidateUrl, { ...body })
        .then((res) => {
            if (res.status === 200) {
                dispatch(setIsUsernameAvailable(true))
                dispatch(setUserNameAvailbleMessage("Username is Availble"))
            } else {
                dispatch(setIsUsernameAvailable(false))
                dispatch(setIsErrorWhileSaving(true))
                dispatch(setUserNameAvailbleMessage("Sorry username is taken"))
            }
        })
        .catch((err) => {
        })
};
export const updateUserName = (username) => (dispatch) => {
    let userUpdateUrl = endpoints.updateUser;
    let body = {
        username: username,
    };

    putRequest(userUpdateUrl, { ...body })
        .then((res) => {
            if (res.status === 200) {
                dispatch(setIsUsernameAvailable(true))
                dispatch(setUserNameAvailbleMessage(res.data.message))
                dispatch(setUserDetails(res.data.updatedData))
            } else {
                const result = res.response
                dispatch(setIsUsernameAvailable(false))
                dispatch(setUserNameAvailbleMessage(result.data.message))
            }
        })
        .catch((err) => {
            const errorResponse = err.response
            dispatch(setIsUsernameAvailable(false))
            dispatch(setUserNameAvailbleMessage(errorResponse.data.message))
        })
};
export const updateUserDetails = (userDetails, setIsInputEnable) => (dispatch, getState) => {
    let userUpdateUrl = endpoints.updateUser;
    const isChanged = isUserNameChanged(userDetails.username, getState)
    if (!isChanged) {
        var payload = {
            ...userDetails,
            address : checkEmptyObj(userDetails.address),
            dob: convertDateFormat(userDetails.dob),
            username: ""
        };
    } else {
        payload = {
            ...userDetails,
            dob: convertDateFormat(userDetails.dob),
        };
    }
    putRequest(userUpdateUrl, cleanObject(payload))
        .then((res) => {
            if (res.status === 200) {
                dispatch(setUserDetails(res.data.updatedData))
                dispatch(setIsErrorWhileSaving(false))
                dispatch(setErrorWhileSaving(""))
                dispatch(setSuccessMessage(res.data.message))
                setIsInputEnable(true)
            } else {
                const result = res.response
                dispatch(setIsErrorWhileSaving(true))
                dispatch(setErrorWhileSaving(result.data.message))
                dispatch(setSuccessMessage(""))
            }
        })
        .catch((err) => {
            const errorResponse = err.response
            console.log("errorResponse: ", errorResponse);
            dispatch(setSuccessMessage(""))
            dispatch(setIsErrorWhileSaving(true))
            dispatch(setErrorWhileSaving(errorResponse.data.message))
        })
};
export const getAllAvatarImages = () => (dispatch) => {
    let allAvatarUrl = endpoints.avatarList;
    getRequest(allAvatarUrl, true)
        .then((res) => {
            dispatch(setAllAvatarImages(res.data))
        })
        .catch((err) => {
        })
};
export const updateAvatar = (avatarUrl) => (dispatch) => {
    let userUpdateUrl = endpoints.updateUser;
    let body = {
        avatarPath: avatarUrl,
    };

    putRequest(userUpdateUrl, { ...body })
        .then((res) => {
            if (res.status === 200) {
                dispatch(setUserDetails(res.data.updatedData))
            }
        })
        .catch((err) => {
        })
};

export const accoladesUser = () => (dispatch) => {
    const accoladesUrl = endpoints.accolades;
    getRequest(accoladesUrl, true)
        .then((res) => {
            if (res?.status === 200) {
                dispatch(setAccoladesDetails(res.data));
            }
        })
        .catch((err) => {
        })
};