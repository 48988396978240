import React, { useEffect } from "react";
import SignIn from "./components/signIn/SignIn";
import HomeLayout from "./layouts/HomeLayout";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Signup from "./components/signup/Signup";
import OTPScreen from "./components/otpScreen/OTPScreen";
import MyProfile from "./components/myProfile/MyProfile";
import Home from "./components/home/Home";
import HowToGainCoins from "./components/howToGainCoins/HowToGainCoins";
import ReferAndEarn from "./components/refer&earn/ReferAndEarn";
// import BecomeAVip from "./components/becomeAvip/BecomeAVip";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./components/termsAndCondition/TermsAndConditions";
import Settings from "./components/settings/Settings";
import ContactPreference from "./components/contactPreference/ContactPreference";
import ChangePassword from "./components/changePassword/ChangePassword";
import PlayersReffered from "./components/refer&earn/PlayersReffered";
import Favourites from "./components/refer&earn/Favourites";
import Favourited from "./components/refer&earn/Favourited";
// import Prizes from "./components/prizes/Prizes";
import RecoverLayout from "./layouts/RecoverLayout";
import Details from "./components/recover/Details";
import Pin from "./components/recover/Pin";
// import UserId from "./components/recover/UserId";
import PasswordScreen from "./components/signup/PasswordScreen";
import Faq from "./components/faq/Faq";
import { getFromLocalStorage } from "./services/helper/Index";
import { setIsSignInSuccess } from "./components/signIn/signSlice";
import { useDispatch, useSelector } from "react-redux";
import GamesFeedback from "./components/gamesFeedback/GamesFeedback";
import { getBannerText, getleaderBoardDetailsAfterLogin, getleaderBoardDetailsBeforeLogin, getuserDetails } from "./components/home/homeApi";
import HitSix from "./components/hitsix/HitSix";
import MatchPredictorgame from "./components/PredictMatch/MatchPredictorgame";
import VcttGame from "./components/virtualCtt/VcttGame";
import ResetPin from "./components/recover/ResetPin";
import UpdatePin from "./components/recover/UpdatePin";
import PinSet from "./components/recover/PinSet";
import CoinsMeanPrizes from "./components/coinsMeanPrizes/CoinsMeanPrizes";
import "./services/socket/index";
import { gethomeBannerImages } from "./components/superBanner/superBannerApi";
import SignupSuccess from "./components/signup/SignupSuccess";
import { getTermAndConditionAfterLogin, getTermAndConditionBeforeLogin } from "./components/termsAndCondition/termsAndConditionApi";

function App() {

  const dispatch = useDispatch()

  const token = getFromLocalStorage("gk")
  const { isSignInSuccess } = useSelector(state => state.signIn)
  useEffect(() => {
    if (token) {
      // dispatch(gethomeBannerImages());
      dispatch(setIsSignInSuccess(true))
      dispatch(getuserDetails())
    } else {
      dispatch(setIsSignInSuccess(false))
    }
  }, [dispatch, token]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });


  useEffect(() => {
    dispatch(gethomeBannerImages())
    dispatch(getBannerText())
    if (!token && !isSignInSuccess) {
      dispatch(getleaderBoardDetailsBeforeLogin())
      dispatch(getTermAndConditionBeforeLogin())
    } else {
      dispatch(getleaderBoardDetailsAfterLogin())
      dispatch(getTermAndConditionAfterLogin())
    }
  }, [isSignInSuccess, dispatch])

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeLayout element={<Home />} />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/otp" element={<OTPScreen />} />
          <Route path="/set-password" element={<PasswordScreen />} />
          <Route path="/signup-success" element={<SignupSuccess />} />
          <Route path="/personal-details"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<MyProfile />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route path="/howtogaincoins"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<HowToGainCoins />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route path="/referandearn"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<ReferAndEarn />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          {/* <Route path="/become-vip" element={<HomeLayout element={<BecomeAVip />} />} /> */}
          {/* <Route path="/privacy-policy" element={<HomeLayout element={<PrivacyPolicy />} />} /> */}
          <Route path="/terms-and-conditions" element={<HomeLayout element={<TermsAndConditions />} />} />
          <Route path="/settings"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<Settings />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route path="/contact-preference"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<ContactPreference />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route path="/change-password"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<ChangePassword />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route path="/playersReferred"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<PlayersReffered />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route path="/favourited"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<Favourited />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route path="/favourites"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<Favourites />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          {/* <Route path="/prizes" element={<HomeLayout element={<Prizes />} />} /> */}
          <Route path="/recover-details" element={<Details />} />
          <Route path="/recover-pin" element={<Pin />} />
          <Route path="/recover-pin/confirmation" element={<ResetPin />} />
          <Route path="/recover-pin/setPin" element={<RecoverLayout title="Recover Pin" element={<UpdatePin />} />} />
          <Route path="/recover-pin/success" element={<RecoverLayout title="Recover Pin" element={<PinSet />} />} />
          {/* <Route path="/recover-userid" element={<HomeLayout element={<RecoverLayout title="Recover User ID" element={<UserId />} />} />} /> */}
          <Route path="/faqs" element={<HomeLayout element={<Faq />} />} />
          <Route path="/gamesfeedback"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<GamesFeedback />} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route path="/game/hit6"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<HitSix />} />
              ) : (
                <Navigate to={"/signin"} />
              )
            }
          />
          <Route path="/game/hit6/:tab"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<HitSix />} />
              ) : (
                <Navigate to={"/signin"} />
              )
            }
          />
          <Route path="/game/mpCric/"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<MatchPredictorgame />} />
              ) : (
                <Navigate to={"/signin"} />
              )
            }
          />
          <Route path="/game/mpCric/:tab"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<MatchPredictorgame />} />
              ) : (
                <Navigate to={"/signin"} />
              )
            }
          />
          <Route path="/game/vctt"
            element={
              token || isSignInSuccess ? (
                <HomeLayout element={<VcttGame />} />
              ) : (
                <Navigate to={"/signin"} />
              )
            }
          />
          <Route path="/coinsmeanprizes" element={<HomeLayout element={<CoinsMeanPrizes />} />} />
          {/* <Route path="/cookiespolicy" element={<CookiesPolicy />} />
          <Route path="/contact" element={<ContactUs />} /> */}
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
