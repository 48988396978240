import React from 'react'
import TrendingCard from "./TrendingCard"
import profile from "../../assets/images/png/profilePicUnset.png"
import trpphy from "../../assets/images/svg/trophy.svg"
function WinnerCard(props) {
    const { playerData } = props
    return (
        <TrendingCard>
            <div className="winner_card_wrapper">
                <div className=" left_section">
                    <div className="image_card">
                    {
                        playerData?.userInfo?.avatarPath ?
                            <img src={playerData?.userInfo?.avatarPath} className="profile_img" alt="profile" />
                            :
                            <img src={profile} className="profile_img" alt="profile" />
                    }
                    </div>
                    <div className="rank">#{playerData?.userInfo?.username}</div>
                    <div className="price">{playerData?.prize}</div>
                </div>
                <div className="right_section">
                    <div className="week">Game week 2</div>
                    <div className="trophy">
                        <img src={trpphy} className="trophy_img" alt="trophy" />
                    </div>
                </div>
                {/* <button className="view_button btn square">
                    Full result
                </button> */}
                {/* <div className="button_section">
                </div> */}
            </div>
        </TrendingCard>
    )
}

export default WinnerCard
