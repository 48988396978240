import React, { useEffect } from 'react'
import SuperBanner from "../superBanner/SuperBanner"
import TrendingEvents from "../trendingEvents/TrendingEvents"
import HitSixGame from "../hitsix/HitSixGame"
import PredictMatch from "../PredictMatch/PredictMatch"
import VirtualCTT from "../virtualCtt/VirtualCTT"
import { useDispatch, useSelector } from 'react-redux'
import { HomePageLoader } from '../common/Loader'
import { setSocketMatchesData } from './homeSlice'


function Home() {
    const { isBannerLoading } = useSelector(state => state.superBanner);
    const { hitSixData, mpData, hitSixBannerText, mpBannerText, vcttBannerText, socketMatchesData } = useSelector(state => state.home);
    const dispatch = useDispatch()

    useEffect(() => {
        window.addEventListener("live-cricket", (e) => {
            dispatch(setSocketMatchesData(e.detail))
        }, false);
    }, []);

    return (
        <React.Fragment>
            {isBannerLoading ?
                <HomePageLoader />
                :
                <>
                    <SuperBanner />
                    <TrendingEvents socketMatchesData={socketMatchesData} />
                    <HitSixGame hitSixData={hitSixData} hitSixBannerText={hitSixBannerText} />
                    <PredictMatch mpData={mpData} mpBannerText={mpBannerText} />
                    <VirtualCTT vcttBannerText={vcttBannerText} />
                </>

            }
        </React.Fragment>
    )
}

export default Home
