import React from 'react'
import profile from "../../assets/images/png/profilePicUnset.png"
import Coin from "../../assets/images/png/coin.png"

function LeaderBoardCard(props) {
    const { playerData } = props
    return (
        <>
            <div className="card_wrapper">
                <div className="leader_board_wrapper">
                    <div className="image_section">
                        <div className="label">{playerData?.position}</div>
                        <div className="imgContainer">
                            {playerData?.userInfo?.avatarPath
                                ?
                                <img src={playerData?.userInfo?.avatarPath} className="profile_img" alt="profile" />
                                :
                                <img src={profile} className="profile_img" alt="profile" />
                            }
                        </div>
                    </div>
                    <div className="content_section">
                        <div className='truncate'>#{playerData?.userInfo?.username}</div>
                        <div>Score: {playerData?.totalPoints}</div>
                        <div>Prize: <img src={Coin} alt='coin' /><span> {playerData?.prize}</span></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeaderBoardCard