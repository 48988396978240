import React,{useEffect} from 'react'
import TACI from "../../assets/images/png/termsAndConditionsImage.png";
import { useSelector } from 'react-redux';
// import TermsAndConditionsDAta from "../config/termsAndConditions.json"

function TermsAndConditions() {
    const { termsAndConditionData } = useSelector(state => state.termsAndCondition);
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);
    
    return (
        <React.Fragment>
            <div className="gain_coins_wrapper">
                <div className="container">
                    <div className="gain_coin_container">
                        <div className='gain_coins_head'>
                            <div className='gain_coins_title'>Terms and Conditions</div>
                        </div>
                        <div className="gain_coin_bannerHeader">
                            <div className="gain_coin_banner">
                                <img alt='banner' height={350} src={TACI}></img>
                            </div>
                            <div className="gain_coin_banner_text">
                                <div className="gain_coinBanner_textHeader">
                                    <h2>Terms And</h2>
                                    <h1>Conditions</h1>
                                </div>
                            </div>
                        </div>
                        <div className="terms_and_conditions_content" dangerouslySetInnerHTML={{ __html: termsAndConditionData }}>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TermsAndConditions