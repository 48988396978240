import React from 'react'
import { useSelector } from 'react-redux';
import ModalBox from '../common/ModalBox'

function GainCoinsModal(props) {
    const { userDetails } = useSelector(state => state.signIn);

    const { weeklyBounus } = userDetails

    return (
        <ModalBox dOpen={props.open} dClose={props.close} isClosable={true} >
            <div className="model_x_container">
                <div className="gaincoin_modal_wrapper">
                    <div className="gaincoin_modal_head">
                        <div className="title">Gameweek  {weeklyBounus && weeklyBounus[0]?.week}</div>
                        <div className="logged_details">
                            <div className='left'>
                                <div>Number of days logged in</div>
                                <div>Total Coins Gained</div>
                            </div>
                            <div className='right'>
                                <div className='brand_secondary'>{weeklyBounus && weeklyBounus[0]?.days} of 6 days</div>
                                <div className='brand_secondary'>{weeklyBounus && weeklyBounus[0]?.coin} coins</div>
                            </div>
                        </div>
                    </div>
                    <div className="gaincoin_body">
                        {weeklyBounus && weeklyBounus.length > 0 &&
                            weeklyBounus?.slice(1)?.map((ele, ind) => {
                                return (
                                    <div className="weekwise" key={ind}>
                                        <div className="title">Gameweek {ele?.week}</div>
                                        <div className="logged_details">
                                            <div className='left'>
                                                <div>Number of days logged in</div>
                                                <div>Total Coins Gained</div>
                                            </div>
                                            <div className='right'>
                                                <div className='brand_secondary'>{ele?.days} of 6 days</div>
                                                <div className='brand_secondary'>{ele?.coin} coins</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        {/* <div className="weekwise">
                            <div className="title">Gameweek 5</div>
                            <div className="logged_details">
                                <div className='left'>
                                    <div>Number of days logged in</div>
                                    <div>Total Coins Gained</div>
                                </div>
                                <div className='right'>
                                    <div className='brand_secondary'>5 of 6 days</div>
                                    <div className='brand_secondary'>6 coins</div>
                                </div>
                            </div>
                        </div>
                        <div className="weekwise">
                            <div className="title">Gameweek 6</div>
                            <div className="logged_details">
                                <div className='left'>
                                    <div>Number of days logged in</div>
                                    <div>Total Coins Gained</div>
                                </div>
                                <div className='right'>
                                    <div className='brand_secondary'>5 of 6 days</div>
                                    <div className='brand_secondary'>6 coins</div>
                                </div>
                            </div>
                        </div>
                        <div className="weekwise">
                            <div className="title">Gameweek 7</div>
                            <div className="logged_details">
                                <div className='left'>
                                    <div>Number of days logged in</div>
                                    <div>Total Coins Gained</div>
                                </div>
                                <div className='right'>
                                    <div className='brand_secondary'>5 of 6 days</div>
                                    <div className='brand_secondary'>6 coins</div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </ModalBox>
    )
}

export default GainCoinsModal