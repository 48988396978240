import React from 'react'
import Games from "../common/HitSix"
import Winner from "../common/Winner"
import LeaderBoard from "../common/LeaderBoard"
import hit6Icon from "../../assets/images/png/hit6-logo.png"
import liveIcon from "../../assets/images/png/liveIcon.png"
import Coin from "../../assets/images/png/coin.png"
import { useNavigate } from 'react-router-dom'
import { getFromLocalStorage, getLocalString } from '../../services/helper/Index'
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

function HitSixGame(props) {
    const { hitSixData, hitSixBannerText } = props
    const { isSignInSuccess } = useSelector((state) => state.signIn);
    const navigate = useNavigate()
    const token = getFromLocalStorage("gk")

    const navigateToGame = () => {
        if (token && isSignInSuccess) {
            navigate("/game/hit6")
        } else {
            navigate("/signin")
        }
    }
    return (
        <React.Fragment>
            {/* {hitSixData && Object.keys(hitSixData).length > 0 && */}
            <div className="container">
                <Games title={"Hit 6"}>
                    <div className="hit_six_card">
                        <div className="top-section">
                            <img className='liveIcon' src={liveIcon} alt="lice ogo" />
                            {hitSixData && hitSixData?.betAmount &&
                                <div className='entry_button'><button>ENTRY =  {hitSixData?.betAmount}<img src={Coin} alt='coin' /></button></div>
                            }
                        </div>
                        <div className='game_title hitsix'>
                            <img src={hit6Icon} alt="hit6 logo" />
                        </div>
                        <div className="middle-section">
                            <div className="left">
                                <div dangerouslySetInnerHTML={{ __html: hitSixBannerText?.bannerText }}></div>
                                <p dangerouslySetInnerHTML={{ __html: hitSixBannerText?.bannerExpiryTime }}></p>
                            </div>
                            <div className="right">
                                {hitSixData && hitSixData?.jacpotPrize &&
                                    <p><span className='prize_detail'>WIN</span><span className='prize_amount'><img src={Coin} alt='coin' />{getLocalString(hitSixData?.jacpotPrize) || 0}</span></p>
                                }
                            </div>
                        </div>
                        <div className="bottom-section">
                            <button className="btn btnLarge" onClick={() => navigateToGame()}>Play Now</button>
                            <p><Link to="/coinsmeanprizes">Coins Mean Prizes! </Link></p>
                        </div>
                    </div>
                </Games>
                {hitSixData && hitSixData?.players && hitSixData?.players.length > 0 && <LeaderBoard leaderBoardData={hitSixData?.players} navigateToLDB={"/game/hit6/ldb"} />}
                {hitSixData && hitSixData?.prevGameWinners && hitSixData?.prevGameWinners.length > 0 && <Winner WinnersData={hitSixData?.prevGameWinners} navigateToResult={"/game/hit6/result"} />}

            </div>
            {/* } */}
        </React.Fragment>
    )
}

export default HitSixGame
