import { postRequest, putRequest } from "../../services/apiCaller";
import { endpoints } from "../../api/ApiEndPoints";
import { getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from "../../services/helper/Index";
import {
    setErrorMessage,
    setEmailError,
    setOtpError,
    setPasswordError,
    setSignupSuccess,
    setOtpSuccess,
    setSignUpfinish,
    setLoader,
    setResendOtp
} from '../signup/SignUpSlice';
import { setIsSignInSuccess, setUserDetails } from "../signIn/signSlice";

export const emailVerification = (email, navigate) => (dispatch) => {
    let regUrl = endpoints.register;
    const payload = {
        email
    }
    dispatch(setLoader(true));
    postRequest(regUrl, payload)
        .then((res) => {
            if (res?.status === 201) {
                dispatch(setSignupSuccess(true));
                setToLocalStorage("ak", res?.data?.token)
                dispatch(setEmailError(false));
                dispatch(setLoader(false));
                navigate("/otp")
            } else {
                dispatch(setSignupSuccess(false));
                dispatch(setLoader(false))
                if (res?.response?.data?.message === "Registration already completed for this user.") {
                    dispatch(setErrorMessage(res?.response?.data?.message))
                    dispatch(setEmailError(true))
                }
            }
        })
        .catch((err) => {
            dispatch(setSignupSuccess(false));
            dispatch(setLoader(false))
            dispatch(setErrorMessage(err?.response?.data?.message))
            dispatch(setEmailError(true))
        })
};

export const resendOtp = () => (dispatch) => {
    let regUrl = endpoints.resendOtp;
    const payload = {};
    const headers = { Authorization: `Bearer ${getFromLocalStorage("ak")}` }
    postRequest(regUrl, payload, { headers })
        .then((res) => {
            if (res?.status === 200) {
                dispatch(setResendOtp(true));
            } else {
                dispatch(setResendOtp(false));
            }
        })
        .catch((err) => {
            dispatch(setResendOtp(false));
        })
};

export const otpVerification = (code, navigate) => (dispatch) => {
    const Url = endpoints.otpGenrate;
    const stringCode = code.join("")
    const otp = parseInt(stringCode)
    const payload = {
        code: otp
    };
    const headers = { Authorization: `Bearer ${getFromLocalStorage("ak")}` }

    dispatch(setLoader(true));
    putRequest(Url, payload, { headers })
        .then((res) => {
            if (res?.status === 200) {
                dispatch(setOtpSuccess(true));
                dispatch(setLoader(false));
                dispatch(setOtpError(false));
                navigate("/set-password");
            } else {
                dispatch(setOtpSuccess(false));
                dispatch(setLoader(false));
                dispatch(setOtpError(true))
                dispatch(setErrorMessage(res?.response?.data?.message))
            }
        })
        .catch((err) => {
            dispatch(setOtpSuccess(false));
            dispatch(setLoader(false));
            dispatch(setErrorMessage(err?.response?.data?.message))
            dispatch(setOtpError(true))
        })
};

export const finishRegistration = (password, referralCode, navigate) => (dispatch) => {
    const Url = endpoints.finish_registration;
    const payload = {
        password: password,
        invitedCode: referralCode,
        avatarPath: "",
        description: ""
    };
    const headers = { Authorization: `Bearer ${getFromLocalStorage("ak")}` }

    dispatch(setLoader(true));
    putRequest(Url, payload, { headers })
        .then((res) => {
            if (res?.status === 200) {
                removeFromLocalStorage("ak");
                dispatch(setSignUpfinish(true));
                dispatch(setLoader(false));
                navigate("/signup-success");
                removeFromLocalStorage("ref");
                setToLocalStorage("gk", res?.data?.token)
                dispatch(setIsSignInSuccess(true))
                dispatch(setUserDetails(res.data.user))
                dispatch(setPasswordError(false));
            } else {
                dispatch(setSignUpfinish(false))
                dispatch(setLoader(false));
                dispatch(setPasswordError(true));
                dispatch(setErrorMessage(res?.response?.data?.message))
            }
        })
        .catch((err) => {
            dispatch(setSignUpfinish(false))
            dispatch(setLoader(false));
            dispatch(setPasswordError(true));
            dispatch(setErrorMessage(err?.response?.data?.message))
        })
};