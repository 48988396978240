import React,{ useEffect } from 'react';
// import { playerRefferedData } from "../constants/constants";
import StackBox from "./StackBox";
import { connect, useDispatch } from "react-redux";
import { getPlayerReffered } from './playersRefferedApi';

function PlayersReffered(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPlayerReffered());
    }, [dispatch]);
    return (
        <div className="container">
            <StackBox title={"Players Referred"} count={props?.playerRefferedData?.length} data={props?.playerRefferedData} noData={props.errorMessage} dataLoading={props.playerRefferedLoading} noDataMsg={"Currently no Players Referred"}/>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        playerRefferedData : state.playersReffered.playerRefferedData,
        errorMessage : state.playersReffered.errorMessage,
        playerRefferedLoading : state.playersReffered.playerRefferedLoading
    };
  }
  export default connect(mapStateToProps)(PlayersReffered)