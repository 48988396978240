import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isUsernameAvailable: false,
    userNameAvailbleMessage: "",
    allAvatarImages: [],
    isErrorWhileSaving: false,
    errorWhileSaving: "",
    successMessage: "",
    accoladesDetails: []
};

export const profilSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setIsUsernameAvailable: (state, action) => {
            state.isUsernameAvailable = action.payload;
        },
        setUserNameAvailbleMessage: (state, action) => {
            state.userNameAvailbleMessage = action.payload;
        },
        setAllAvatarImages: (state, action) => {
            state.allAvatarImages = action.payload;
        },
        setIsErrorWhileSaving: (state, action) => {
            state.isErrorWhileSaving = action.payload;
        },
        setErrorWhileSaving: (state, action) => {
            state.errorWhileSaving = action.payload;
        },
        setSuccessMessage: (state, action) => {
            state.successMessage = action.payload;
        },
        setAccoladesDetails: (state, action) => {
            state.accoladesDetails = action.payload;
        },
    }
});

export const {
    setIsUsernameAvailable,
    setUserNameAvailbleMessage,
    setAllAvatarImages,
    setIsErrorWhileSaving,
    setErrorWhileSaving,
    setSuccessMessage,
    setAccoladesDetails
} = profilSlice.actions;


export default profilSlice.reducer;
