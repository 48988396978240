import React, { useEffect } from 'react';
import Accordion from '../common/Accordion';
import coin from "../../assets/images/png/coin.png";
import CoinsMeanPrizesDesktop from "../../assets/images/png/CoinsMeanPrizesDesktop.png";
import CoinsMeanPrizesMobile from "../../assets/images/png/CoinsMeanPrizesMobile.png";
import { useDispatch, connect } from 'react-redux';
import { getCoinsMeanPrizesData } from './coinsMeanPrizesApi';

function CoinsMeanPrizes(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCoinsMeanPrizesData());
        window.scrollTo(0, 0);
    },[]);

    return (
        <React.Fragment>
            <div className="coins_prizes_wrapper">
                <div className="container">
                    <div className="coins_prizes_container">
                        <div className="coins_prizes_header">
                            <div className="coins_prizes_banner">
                                <div className="desktop_banner hideDesktop">
                                    <img src={CoinsMeanPrizesDesktop} alt="coins Mean Prizes Banner" />
                                </div>
                                <div className="mobile_banner hideMobile">
                                    <img src={CoinsMeanPrizesMobile} alt="coins Mean Prizes Banner" />
                                </div>
                            </div>
                        </div>
                        <div className="coins_prizes_body">
                            <div className="coins_prizes_cards">

                                {props?.coinsMeanPrizes?.coinsMeanPrizesData?.prizes?.map((ele, index) => {
                                    return (
                                        <div className="card" key={index}>
                                            <div className="card_top">
                                                <img src={require(`../../assets/images/prizes/prize${ele.prizeId}.png`)} alt="Match Cap" />
                                            </div>
                                            <div className="card_content">
                                                <div className="card_content_header">
                                                    <h3>{ele.title}</h3>
                                                    <img src={coin} alt="coin" />{ele?.coins}
                                                </div>
                                                <ul>
                                                    {ele.text.map((txt, ind) => {
                                                        return (
                                                            <div className="arrow_list" key={ind}>
                                                                <li>{txt} </li>
                                                            </div>
                                                        )
                                                    })}

                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='cmp_Accordion_footer'>
                                *All prizes are subject to availability. In the event that a prize isn’t available, we will contact you to give you options of replacement prizes.
                            </div>
                            <div className="cmp_accordion">
                                <div className="cmp_accordion_header">
                                    <p>FREQUENTLY ASKED QUESTIONS</p>
                                    <h2> Learn More about redeeming points</h2>
                                </div>
                                {props?.coinsMeanPrizes?.coinsMeanPrizesData?.prizesFaqs?.map((text, ind) => {
                                    return (
                                        <div className="cmp_Accordion_Content" key={ind}>
                                            <Accordion title={text.title.toString()}
                                                content={
                                                    <div className="accordion_content" >
                                                        <div className="accordion_sectionOne">
                                                            <div className="content" dangerouslySetInnerHTML={{__html : text.text}}>
                                                                    {/* {text.text} */}
                                                            </div >
                                                        </div >
                                                    </div>
                                                } />
                                        </div>
                                    )
                                })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
function mapStateToProps(state) {
    return {
        coinsMeanPrizes: state.coinsMeanPrizes
    };
}
export default connect(mapStateToProps)(CoinsMeanPrizes)