import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    homeBannerImages: [],
    isBannerLoading: false
};

export const superBannerSlice = createSlice({
    name: "superBanner",
    initialState,
    reducers: {
        setHomeBannerImages: (state, action) => {
            state.homeBannerImages = action.payload;
        },
        setIsBannerLoading: (state, action) => {
            state.isBannerLoading = action.payload;
        },
    }
});

export const {
    setHomeBannerImages,
    setIsBannerLoading
} = superBannerSlice.actions;


export default superBannerSlice.reducer;
