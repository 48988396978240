import React, { useEffect, useState } from 'react';
import ModalBox from "../common/ModalBox";
import { BiTrashAlt } from "react-icons/bi";
import { RiAddFill } from "react-icons/ri";
import { setEmail, setErrorMessage, setReferSuccess } from './referAndEarnSlice';
import { useDispatch, useSelector } from 'react-redux';
import { referUser } from './referAndEarnApi';

function InviteFriends(props) {
    const [inviteCount, setInviteCount] = useState(1);
    const [count, setCount] = useState(1);
    const [invitedlist, setInvitedlist] = useState([]);
    const [id, setId] = useState(0);
    const dispatch = useDispatch();
    const regExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    const {
        referSuccess,
        errorMessage
    } = useSelector((state) => state.refer);

    useEffect(() => {
        const date = new Date().getTime()
        if ((count === inviteCount)) {
            setInvitedlist([...invitedlist, { id: date, email: "", isInvited: false }])
            setCount(count + 1)
            dispatch(setErrorMessage(""));
        };
    }, [inviteCount, count, invitedlist]);


    const handleEdit = (id, email) => {
        dispatch(setEmail(email));
        dispatch(setErrorMessage(""));
        const tempData = [...invitedlist]
        const index = invitedlist.findIndex((ele => ele.id === id))
        tempData[index] = { email, id: tempData[index]?.id, isInvited: tempData[index]?.isInvited }
        setInvitedlist(tempData);
    };

    const handleInvite = (index) => {
        dispatch(setErrorMessage(""));
        const filterData = invitedlist.filter((ele => ele.id === index));
        if (filterData[0].email === "") {
            dispatch(setErrorMessage("Please Enter Email"));
        } else if (!regExp.test(filterData[0].email)) {
            dispatch(setErrorMessage("Invalid Email"));
        } else {
            setId(index);
            dispatch(setErrorMessage(""));
            dispatch(referUser(filterData[0].email));
            dispatch(setReferSuccess(false));
        }
    };

    useEffect(() => {
        if (referSuccess) {
            const tempData = [...invitedlist]
            const index = invitedlist.findIndex((ele => ele.id === id))
            tempData[index] = { email: tempData[index]?.email, id: tempData[index]?.id, isInvited: true }
            setInvitedlist(tempData);
            dispatch(setReferSuccess(false));
            dispatch(setEmail(""));
            dispatch(setErrorMessage(""));
        }
    }, [referSuccess, id]);

    const handleDelete = (id) => {
        dispatch(setErrorMessage(""));
        setInviteCount(prev => prev === 1 ? 1 : prev - 1);
        setCount(prev => prev === 1 ? 1 : prev - 1);
        if (invitedlist.length > 1) {
            const filteredData = invitedlist.filter((ele => ele.id !== id))
            setInvitedlist(filteredData)
        }
    };

    const handleAddMore = () => {
        dispatch(setEmail(""));
        setInviteCount(prev => prev + 1)
    };

    return (
        <ModalBox dOpen={props.open !== ""} dClose={props.close} isClosable={props.closable} >
            <div className="invite_friends_model">
                <div className="title">
                    Invite Friends
                </div>
                {/* <div className="content">
                    AlexVJ has invited you to play Hit 6 a great new cricket game where you can play and win big for free
                </div> */}
                <div className="form_group">
                    {
                        invitedlist?.map((ele, ind) => {
                            return (
                                <div className="form_group_inline" key={ind}>
                                    <input
                                        type="text"
                                        className="form_input"
                                        value={ele.email}
                                        onChange={(e) => handleEdit(ele.id, e.target.value)}
                                        placeholder={"Enter email address"}
                                    />
                                    {
                                        ele.isInvited ?
                                            <button className="btn_invite invited">
                                                invited
                                            </button>
                                            :
                                            <button
                                                className="btn_invite invite"
                                                onClick={() => handleInvite(ele.id)}>
                                                invite
                                            </button>

                                    }
                                    {invitedlist.length !== 1 && <span>{!ele.isInvited && <BiTrashAlt className="icon_delete" onClick={() => handleDelete(ele.id)} />}</span>}
                                </div>
                            )
                        })
                    }
                    {/* <div className="form_group_inline">
                        <input type="text" className="form_input" placeholder="Enter email address" />
                        <button className="btn btn_invite btn-primary">Invite</button>
                        <BiTrashAlt className="icon_delete" />
                    </div> */}
                    <div className='error_message'>{errorMessage}</div>
                </div>
                <div className="add_more">
                    <RiAddFill className="icon_add" onClick={() => handleAddMore()} />
                    Add more friends
                </div>

            </div>
        </ModalBox>
    )
};

export default InviteFriends
