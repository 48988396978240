function SuperCard({ title, children }) {
  return (
    <div className="super_card_wrapper">
      <div className="super_card_title">
        <p>{title}</p>
      </div>
      <div className="super_card">
        {children}
      </div>
    </div>
  )
}

export default SuperCard