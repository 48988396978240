import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonLoader } from '../common/Loader';
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { finishRegistration } from './SignUpApi';
import LOGO from "../../assets/images/png/logo.png";
import { BsCheck } from "react-icons/bs";
import { getFromLocalStorage, removeFromLocalStorage } from '../../services/helper/Index';
import {
    setPassword,
    setConfirmPassword,
    setReferralCode,
    setErrorMessage,
    setPasswordError
} from "./SignUpSlice";

function PasswordScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [disabledBtn, setDisableBtn] = useState(false);
    const [iconPass, setIconPass] = useState(false);
    const [iconConfimPass, setIconConfimPass] = useState(false);
    const refCode = getFromLocalStorage(("ref"));
    const regExp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/;

    const {
        email,
        password,
        confirmPassword,
        loader,
        referralCode,
        errorMessage,
        passwordError
    } = useSelector((state) => state.signup);
    const onChangeText = (e) => {
        dispatch(setPasswordError(false));
        if (e.target.name === "password") {
            dispatch(setPassword(e.target.value));
        }
        if (e.target.name === "confimPassword") {
            dispatch(setConfirmPassword(e.target.value));
        }
        if (e.target.name === "referral") {
            dispatch(setReferralCode(e.target.value));
        }

    };

    useEffect(() => {
        if (password === "") {
            setDisableBtn(false)
        } else if (confirmPassword === "") {
            setDisableBtn(false)
        } else (
            setDisableBtn(true)
        )
    }, [password, confirmPassword]);

    useEffect(() => {
        if (refCode) {
            dispatch(setReferralCode(refCode));
        }
    }, [refCode]);

    const handleSubmit = (e) => {
        e.preventDefault()
        if (password === "") {
            dispatch(setPasswordError(true));
            dispatch(setErrorMessage("invalid password"));
        } else if (password !== confirmPassword) {
            dispatch(setPasswordError(true));
            dispatch(setErrorMessage("password not match"));
        } else if (password.length < 6) {
            dispatch(setPasswordError(true));
            dispatch(setErrorMessage("password must have 6 alphanumeric characters"));
        } else if (!regExp.test(password)) {
            dispatch(setPasswordError(true));
            dispatch(setErrorMessage("password should have at least 1 lowercase letter, 1 uppercase letter, 1 number."));
        } else {
            dispatch(finishRegistration(password, referralCode, navigate));
            dispatch(setPasswordError(false));
        }
    };

    useEffect(() => {
        dispatch(setPasswordError(false));
    }, []);

    const handleEyeIcon = (key) => {
        if (key === "pass") {
            setIconPass(!iconPass)
        }
        if (key === "confimPass") {
            setIconConfimPass(!iconConfimPass)
        }
    };

    const handleLogo = () => {
        navigate("/")
        window.location.reload();
        removeFromLocalStorage("ak")
    };

    return (
        <React.Fragment>
            <div className='sign_wrapper'>
                <div className="container">
                    <div>
                        <img src={LOGO} alt="logo" className='sign_logo' onClick={handleLogo} />
                    </div>
                    <div className="card_title">
                        Enter Details
                    </div>
                    <form onSubmit={handleSubmit} className="password_wrapper">
                        <div className="password_title">
                            {email}
                        </div>
                        <div className='form_input'>
                            <input
                                type={iconPass ? "text" : "password"}
                                className="form_input"
                                name='password'
                                onChange={(e) => onChangeText(e)}
                                placeholder="Enter Password"
                                minLength={6}
                                maxLength={15}
                            />
                            <span className='pointer' onClick={() => handleEyeIcon("pass")}>{iconPass ? <VscEye /> : <VscEyeClosed />}</span>
                        </div>
                        <div className='form_input'>
                            <input
                                type={iconConfimPass ? "text" : "password"}
                                className="form_input"
                                name='confimPassword'
                                onChange={(e) => onChangeText(e)}
                                placeholder="Confirm Password"
                                minLength={6}
                                maxLength={15}
                            />
                            <span className='pointer' onClick={() => handleEyeIcon("confimPass")}>{iconConfimPass ? <VscEye /> : <VscEyeClosed />}</span>
                        </div>
                        <div className='form_input'>
                            <input
                                type={"text"}
                                className="form_input"
                                name='referral'
                                value={referralCode}
                                onChange={(e) => onChangeText(e)}
                                placeholder="Referral Code"
                            />
                        </div>
                        {passwordError && <div className='error_message'>{errorMessage}</div>}
                        <div className="pin_contents">
                            <div className="pin_content">
                                <span><BsCheck className="check_icon" /></span>
                                <p>Must have at least 6 alphanumeric characters </p>
                            </div>
                            <div className="pin_content">
                                <span><BsCheck className="check_icon" /></span>
                                <p>One of which will need to be numeric</p>
                            </div>
                            <div className="pin_content">
                                <span><BsCheck className="check_icon" /></span>
                                <p>Alpha characters will need to be a combination of upper and lower case (at least one of each)</p>
                            </div>
                        </div>
                        <button
                            disabled={!disabledBtn}
                            className={`${disabledBtn ? "btn btn-strech" : "btn btn-strech disabled"}`}
                            type='submit'>{loader ? <ButtonLoader /> : "SIGN UP"}
                        </button>
                    </form>
                </div>
            </div>

        </React.Fragment>
    )
}

export default PasswordScreen
