import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import LOGO from "../../assets/images/png/logo.png";
import { getuserSignin, validateEmail } from './signInApi';
import { setSignErrorMessage, setSignInSuccessMessage } from './signSlice';
import { ButtonLoader } from "../common/Loader";
import BackButton from '../common/BackButton';
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import signUpBg from "../../assets/images/png/signUp.png";


function SignIn() {
    const dispatch = useDispatch()
    const [logindetails, setLogindetails] = useState({
        email: "",
        password: ""
    })
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [iconPass, setIconPass] = useState(false);
    const { signErrorMessage } = useSelector(state => state.signIn)
    const { signinLoading } = useSelector(state => state.signIn);
    const navigate = useNavigate()

    const inputHandler = (e) => {
        const name = e.target.name
        const value = e.target.value
        setEmailError("")
        setPasswordError("")
        dispatch(setSignErrorMessage(""))
        setLogindetails({ ...logindetails, [name]: value })
    }



    const handleSignIn = (logindetails, e) => {
        e.preventDefault();
        if (logindetails.email.trim() === "" && logindetails.password.trim() === "") {
            setEmailError("Email is Required")
            setPasswordError("Password is Required")
        }
        else if (logindetails.email.trim() === "") {
            setEmailError("Email is Required")
            setPasswordError("")
        }
        else if (logindetails.password.trim() === "") {
            setEmailError("")
            setPasswordError("Password is Required")
        } else if (logindetails.password.length < 6) {
            setPasswordError("password must have 6 alphanumeric characters");
        } else if (!validateEmail(logindetails.email)) {
            setEmailError("Invalid Email")
            setPasswordError("")
        } else {
            setEmailError("")
            setPasswordError("")
            dispatch(getuserSignin(logindetails.email, logindetails.password, navigate))
        }
    }
    const handleEyeIcon = (key) => {
        if (key === "pass") {
            setIconPass(!iconPass)
        }
    };
    useEffect(() => {
        return () => {
            setEmailError("")
            setPasswordError("")
            dispatch(setSignErrorMessage(""))
            dispatch(setSignInSuccessMessage(""))
        }
    }, [dispatch]);

    return (
        <React.Fragment>
            <BackButton />
            <div className='sign_wrapper'>
                <div className="container">
                    <Link to={"/"}>
                        <div className="sign_logo">
                            <img src={LOGO} alt="logo" />
                        </div>
                    </Link>
                        <div className='signin_title'>
                            SIGN IN
                        </div>
                    <div className="sign_box">

                        <form onSubmit={(e) => handleSignIn(logindetails, e)} className='form_group'>
                            <div className="form_signin_input">
                                <input type="email" placeholder='EMAIL' name='email' value={logindetails.email} onChange={(e) => inputHandler(e)} />
                            </div>
                            <div className='error-msg'>
                                {emailError}
                            </div>
                            <div className="form_signin_input">
                                <input type={iconPass ? "text" : "password"}
                                    className='signin_pass'
                                    placeholder='PASSWORD'
                                    name='password'
                                    value={logindetails.password}
                                    onChange={(e) => inputHandler(e)}
                                    minLength={6}
                                    maxLength={15}
                                />
                                <span className='pointer signin_eye' onClick={() => handleEyeIcon("pass")}>{iconPass ? <VscEye /> : <VscEyeClosed />}</span>
                            </div>
                            <div className='error-msg'>
                                {passwordError}
                            </div>
                            <div className='error-msg'>
                                {signErrorMessage}
                            </div>
                            <button className='btn btnMedium' type='submit'>
                                {signinLoading ? <ButtonLoader /> : "SIGN IN"}
                            </button>
                        </form>
                        {/* <p><span>LOGIN BY OTP</span></p> */}
                        <p>If you do not have an account? &nbsp;<Link to={"/signup"}><span> Register here</span></Link></p>
                        <p>Forgot Password &nbsp;<Link to={"/recover-details"}><span> Click here</span></Link></p>
                    </div>
                    <div className='signUpBackground'>
                        <img src={signUpBg} alt={"bg"} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SignIn