import { endpoints } from "../../api/ApiEndPoints";
import {
    setRecoverPinResponseSuccess,
    setRecoverPinResponseError,
    setUpdatePinResponseSuccess,
    setUpdatePinResponseError,
    setCheckPinSuccess,
    setCheckPinError,
    setEmail,
} from "./recoverPinSlice";
import { postRequest } from "../../services/apiCaller";
import { getFromLocalStorage, setToLocalStorage } from "../../services/helper/Index";

const pinRegX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/;


export const validatePin = (pin) => {
    if (pinRegX.test(pin)) return true
    return false
}
export const recoverPin = (email, navigate) => (dispatch) => {
    let recoverPinUrl = endpoints.recoverPin;
    let body = {
        email: email
    };

    postRequest(recoverPinUrl, { ...body })
        .then((res) => {
            if (res.status === 200) {
                dispatch(setRecoverPinResponseSuccess(res.data.message));
                dispatch(setEmail(email));
                setToLocalStorage("rp", res?.data?.token)
                dispatch(setRecoverPinResponseError(""));
                navigate("/recover-pin/confirmation");
            }
            else {
                dispatch(setRecoverPinResponseError(res?.response?.data?.message));
                dispatch(setRecoverPinResponseSuccess(""));
            }
        })
        .catch((err) => {
            const errorResponse = err.response
            dispatch(setRecoverPinResponseSuccess(""))
            dispatch(setRecoverPinResponseError(errorResponse.data.message));
        })
}

export const setPin = (code, navigate, password) => (dispatch) => {
    let setPinUrl = endpoints.checkPin;
    let body = {
        code: code
    };
    const headers = { Authorization: `Bearer ${getFromLocalStorage("rp")}` }
    postRequest(setPinUrl, { ...body }, { headers })
        .then((res) => {
            if (res.status === 200) {
                // dispatch(setCheckPinSuccess(res.data.message));
                dispatch(setCheckPinError(""));
                dispatch(updatePin(password, navigate));
            }
            else {
                dispatch(setCheckPinError("Session Expired!!!"));
                dispatch(setCheckPinSuccess(""))
            }
        })
}
export const updatePin = (password, navigate) => (dispatch) => {
    let updatePinUrl = endpoints.updatePin;
    let body = {
        password: password
    };
    const headers = { Authorization: `Bearer ${getFromLocalStorage("rp")}` }
    postRequest(updatePinUrl, { ...body }, { headers })
        .then((res) => {
            if (res.status === 200) {
                dispatch(setUpdatePinResponseSuccess(res.data.message));
                dispatch(setUpdatePinResponseError(""));
                navigate("/recover-pin/success");
            }
            else {
                dispatch(setUpdatePinResponseError("Session Expired!!!"));
                dispatch(setUpdatePinResponseSuccess(""));
            }
        })
        .catch((err) => {
            const errorResponse = err.response
            dispatch(setUpdatePinResponseSuccess(""))
            dispatch(setUpdatePinResponseError(errorResponse.data.message));
        })
}