import React from "react";
import PinSetImage from "../../assets/images/png/pin_set.png";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { removeFromLocalStorage } from "../../services/helper/Index";

function PinSet() {
    const navigate = useNavigate();
    const loginNavigation = () => {
        navigate("/signin");
        window.location.reload();
        removeFromLocalStorage("rp");
    }
    return (
        <>
            <div className="pinSet_wrapper">
                <div className="container">
                    <div className="recover_details_body">
                        <div className="pinSet_image">
                            <img src={PinSetImage} alt="pin success" />
                        </div>
                        <div className="pinSet_text">
                            Your new PIN has been Set Successfully
                        </div>
                        
                            <button className="btn form_btn btn-strech" onClick={loginNavigation}>
                                BACK TO LOGIN
                            </button>
                       
                    </div>
                </div>

            </div>
        </>
    )
}

export default PinSet