import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getFromLocalStorage } from '../../services/helper/Index'

function HitSix() {
    const [accessToken, setAccessToken] = useState("");
    const [gameTab, setGameTab] = useState("");
    const [isGameIdAvailable, setIsGameIdAvailable] = useState(true)
    const { userDetails } = useSelector(state => state.signIn)
    const { hitSixData } = useSelector(state => state.home);

    const navigate = useNavigate()


    var platformId = process.env.REACT_APP_GAME_PLATFORM_ID;
    var operatorId = process.env.REACT_APP_GAME_OPERATOR_ID;
    var brand = process.env.REACT_APP_GAME_BRAND;

    const { tab } = useParams()

    const memoizedGetFromLocalStorage = useCallback(getFromLocalStorage, []);


    useEffect(() => {
        const token = memoizedGetFromLocalStorage("gk");
        setAccessToken(token);
    }, [memoizedGetFromLocalStorage]);

    useEffect(() => {
        if (tab === undefined) {
            setGameTab("")
        } else {
            setGameTab(tab)
        }
    }, [tab]);


    useEffect(() => {
        if (hitSixData?.lastWeekGameId) {
            setIsGameIdAvailable(true)
        } else {
            setIsGameIdAvailable(false)
        }
    }, [hitSixData])


    return (
        <React.Fragment>
            {isGameIdAvailable ? <iframe
                className="gameScreen_frame"
                title="hit-six-game"
                src={`https://dev-games-rgs.sportsit-tech.net/games/hit6/index.html#/?token=${accessToken}&playerId=${userDetails.id}&playerName=${userDetails.username}&platformId=${platformId}&operatorId=${operatorId}&brand=${brand}&currencyCode=${"INR"}&languageCode=${"en"}&region=${"IN"}&launchType=${gameTab}&gameId=${hitSixData?.lastWeekGameId}`}>
            </iframe>
                :
                <iframe
                    className="gameScreen_frame"
                    title="hit-six-game"
                    src={`https://dev-games-rgs.sportsit-tech.net/games/hit6/index.html#/?token=${accessToken}&playerId=${userDetails.id}&playerName=${userDetails.username}&platformId=${platformId}&operatorId=${operatorId}&brand=${brand}&currencyCode=${"INR"}&languageCode=${"en"}&region=${"IN"}&launchType=${gameTab}`}>
                </iframe>}
        </React.Fragment>
    )
}

export default HitSix;
