import io from "socket.io-client";
let socket = null;
const GAMES_SOCKET_END_POINT = process.env.REACT_APP_GAMES_SOCKET_URL



export const socketConnect = () => {
  socket = io(GAMES_SOCKET_END_POINT, {
    transports: ["websocket"],
    path: '/bo/socket.io'
  });

  socket.on("live-cricket", function (update) {
    // dispatch(setSocketMatchesData([...update.liveMatches, ...update.scheduledMatches]))
    // console.log("update", update);
    if (update) {
      const event = new CustomEvent("live-cricket", {
        detail: [...update.liveMatches, ...update.scheduledMatches, ...update.completedMatches]
      });
      window.dispatchEvent(event);
    }
  });

  socket.on('connect', () => {
    console.log('Connected to socket.io server');
  });

  socket.on('error', (err) => {
    console.error('Socket error:', err);
  });
};

socketConnect();
