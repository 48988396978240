import React, { useEffect, useState } from 'react';
import { recoverPin, validatePin } from './recoverPinApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../common/BackButton';
import { Link } from "react-router-dom";
import LOGO from "../../assets/images/png/logo.png";
import { setRecoverPinResponseError } from './recoverPinSlice';


function UserId() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const pinRegX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    const {
        recoverPinResponseError
    } = useSelector((state) => state.recoverPin)

    const inputHandler = (e) => {
        dispatch(setRecoverPinResponseError(""));
        const value = e.target.value;
        setEmail(value);
    };

    const handleRecoverPin = () => {
        if (email === "") {
            dispatch(setRecoverPinResponseError("Please Enter your registered Email ID"));
        } else if (!pinRegX.test(email)) {
            dispatch(setRecoverPinResponseError("Please Enter Valid Email ID"));
        } else {
            dispatch(setRecoverPinResponseError(""));
            dispatch(recoverPin(email, navigate))
        }
    };

    useEffect(() => {
        dispatch(setRecoverPinResponseError(""));
    }, []);

    const handleKeypress = (e) => {
        //it triggers by pressing the enter key
        if (e.key === "Enter") {
            handleRecoverPin();
        }
    };

    return (
        <React.Fragment>
            <div className="recover_layout" onKeyPress={handleKeypress}>
                <div className="header" id="page-layoutHeader">
                    <div className="layout-header">
                        <div className="mainHeader">
                            <div className="container">
                                <div className="mainHeader-container">
                                    <div className="mainHeader-logo">
                                        <Link to="/">
                                            <img src={LOGO} alt="Loading" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header_recover">
                    <div className="container">
                        <BackButton />
                        <div className="title">Recover Pin</div>
                    </div>
                </div>
                <div className="container">
                    <div className="recover_details_body">
                        <div className="text_cotaniner">
                            <p>Enter the email address registered on your account to recover your PIN.</p>
                        </div>
                        <div className="form_wrapper">
                            <div className="form_container">
                                <div className="form_group">
                                    <label htmlFor="pin">Email Address</label>
                                    <input type="text" className="form_input" id="pin" name="email" onChange={(e) => inputHandler(e)} value={email} />
                                    <div className='error-msg'>
                                        {recoverPinResponseError && recoverPinResponseError}
                                    </div>
                                    <button className="btn form_btn btn-strech" onClick={() => handleRecoverPin()}>RECOVER MY PIN</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UserId