import { endpoints } from "../../api/ApiEndPoints";
import { getRequest } from "../../services/apiCaller";
import { setCommonError, setTermsAndConditionData } from "./termsAndConditionSlice";

export const getTermAndConditionBeforeLogin = () => (dispatch) => {
    let termsConditionUrl = endpoints.termsAndConditionBeforeLogin;
    dispatch(setTermsAndConditionData([]))
    getRequest(termsConditionUrl, false)
        .then((res) => {
            if (res.status === 200) {
                dispatch(setTermsAndConditionData(res.data.termsAndConditions))
            }
        })
        .catch((err) => {
            dispatch(setCommonError(err))
        })
};

export const getTermAndConditionAfterLogin = () => (dispatch) => {
    let termsConditionUrl = endpoints.termsAndConditionAfterLogin;
    dispatch(setTermsAndConditionData([]))
    getRequest(termsConditionUrl, false)
        .then((res) => {
            if (res.status === 200) {
                dispatch(setTermsAndConditionData(res.data.termsAndConditions))
            }
        })
        .catch((err) => {
            dispatch(setCommonError(err))
        })
};