import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: "",
    coinsMeanPrizesData: "",
};

export const coinsMeanPrizesSlice = createSlice ({
    name: "coinsMeanPrizes",
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload;
        },
        setCoinsMeanPrizesData: (state, action) => {
            state.coinsMeanPrizesData = action.payload;
        },
    }
});

export const {
    setCoinsMeanPrizesData,
    setError
} = coinsMeanPrizesSlice.actions;

export default coinsMeanPrizesSlice.reducer;