import React from 'react'
import Games from "../common/HitSix"
import LeaderBoard from "../common/LeaderBoard"
import Winner from "../common/Winner"
import mpLogo from "../../assets/images/png/mp-logo.png";
import liveIcon from "../../assets/images/png/liveIcon.png"
import Coin from "../../assets/images/png/coin.png"
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFromLocalStorage, getLocalString } from '../../services/helper/Index';
import { Link } from "react-router-dom";

function PredictMatch(props) {
    const { mpData, mpBannerText } = props
    const { isSignInSuccess } = useSelector((state) => state.signIn);
    const navigate = useNavigate()
    const token = getFromLocalStorage("gk")

    const navigateToGame = () => {
        if (token && isSignInSuccess) {
            navigate("/game/mpcric/")
        } else {
            navigate("/signin")
        }
    }



    return (
        <div className="container">
            <Games title={"Match Predictor"}>
                <div className="hit_six_card">
                    <div className="top-section">
                        <img className='hidden' src={liveIcon} alt="lice ogo" />
                        {mpData && mpData?.betAmount &&
                            <div className='entry_button'><button>ENTRY={mpData.betAmount} <img src={Coin} alt='coin' /></button></div>
                        }
                    </div>
                    <div className='game_title'>
                        <img src={mpLogo} alt="MP logo" />
                    </div>
                    <div className="middle-section">
                        <div className="left">
                            <div dangerouslySetInnerHTML={{ __html: mpBannerText?.bannerText }}></div>
                            {/* <div>
                                Predict <span>7</span> Key Events <br /> In a Match to Win Big
                            </div> */}
                            {/* <p>Entries by 3:00pm on Friday 24th March 2023 and T&Cs apply</p> */}
                        </div>
                        <div className="right">
                            {mpData && mpData?.jacpotPrize &&
                                <p><span className='prize_detail'>WIN</span><span className='prize_amount'><img src={Coin} alt='coin'></img>{getLocalString(mpData?.jacpotPrize || 0)}</span></p>
                            }
                        </div>
                    </div>
                    <div className="bottom-section">
                        <button className="btn btnLarge" onClick={() => navigateToGame()}>Play Now</button>
                        <p><Link to="/coinsmeanprizes">Coins Mean Prizes! </Link></p>
                    </div>
                </div>
            </Games>
            {mpData && mpData?.players && <LeaderBoard leaderBoardData={mpData.players} navigateToLDB={"/game/mpcric/ldb"} />}
            {mpData && mpData?.prevGameWinners && mpData?.prevGameWinners.length > 0 && <Winner WinnersData={mpData.prevGameWinners} navigateToResult={"/game/mpcric/result"} />}
        </div>
    )
}

export default PredictMatch
