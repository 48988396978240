import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import StackBox from "./StackBox";
import { getFavourited } from './favouritedApi';
import { getFavourites } from './favouritesApi';

const Favourited = (props) => {
    const dispatch = useDispatch();
    const [localFavourited, setLocalFavourited] = useState(props?.favouritedData);
    useEffect(() => {
        dispatch(getFavourites());
        dispatch(getFavourited());
    }, [dispatch]);

    useEffect(() => {
        if (props?.favouritedData.length > 0) {
            let updatedFavourited = props?.favouritedData.map(obj => {
                return Object.assign({}, obj, {
                    favourite: props?.favouritesData.some(aObj => aObj.id === obj.id)
                });
            });
            setLocalFavourited(updatedFavourited);
        }
    }, [props?.favouritedData, props?.favouritesData]);
    return (
        <>
            <div className="container">
                <StackBox title={"Favourited"} count={localFavourited?.length} data={localFavourited} noData={props?.errorMessage} dataLoading={props.favouritedLoading} noDataMsg={"Currently no Favourited Available"} />
            </div>
        </>
    )
}
function mapStateToProps(state) {
    return {
        favouritedData: state.favourited.favouritedData,
        errorMessage: state.favourited.errorMessage,
        favouritedLoading: state.favourited.favouritedLoading,
        favouritesData: state.favourites.favouritesData,
    };
}
export default connect(mapStateToProps)(Favourited)
