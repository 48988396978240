import React from 'react'
import LeaderBoardCard from './LeaderBoardCard'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFromLocalStorage } from '../../services/helper/Index';

function LeaderBoard(props) {
    const { leaderBoardData, navigateToLDB } = props
    const { isSignInSuccess } = useSelector((state) => state.signIn);
    const token = getFromLocalStorage("gk")
    const navigate = useNavigate()

    const navigateToGame = () => {
        if (token && isSignInSuccess) {
            navigate(navigateToLDB)
        } else {
            navigate("/signin")
        }
    }

    return (
        <div className="current_leaderboard">
            <div className="leaderboard_title">
                Current Leaderboard
            </div>
            <div className="week">
                <span>Game week-3</span>
                <span className='pointer' onClick={() => navigateToGame()}>View all</span>
            </div>
            <div className="profile_card_section">
                {leaderBoardData && leaderBoardData.length > 0 &&
                    leaderBoardData.slice(0, 3).map((player) => {
                        return (
                            <LeaderBoardCard key={player.id} playerData={player} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default LeaderBoard