import { Link } from "react-router-dom";
import React from 'react'
import logo from "../../assets/images/png/logo.png";
import { useSelector } from "react-redux";
import { getFromLocalStorage } from "../../services/helper/Index";


function Footer() {
    const { isSignInSuccess } = useSelector(state => state.signIn)
    const token = getFromLocalStorage("gk")

    return (
        <div className="footer_container">
            <div className="container">
                <div className="content_section">
                    <Link to="/"><div>
                        <img src={logo} alt="cricctt logo" className="logo" />
                    </div></Link>
                    {/* <div className="content">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    </div> */}
                </div>
                <div className="links_section">
                    <div>
                        <div className="title">Support</div>
                        {/* <Link to={"/privacy-policy"}><p>Privacy Policy</p></Link> */}
                        <Link to={"/terms-and-conditions"}><p>Terms and Conditions</p></Link>
                        {/* <Link to="/cookiespolicy"><p>Cookies Policy</p></Link>  */}
                        <Link to={"/faqs"}><p>FAQ's</p></Link>
                        {isSignInSuccess && token ? <Link to="/gamesfeedback"><p>Contact Us</p></Link> : null}
                    </div>
                    <div>
                        <div className="title">Follow Us</div>
                        <a href="https://twitter.com/cricctt" target={"_blank"} rel="noreferrer"><p>Twitter</p></a>
                        <a href="https://telegram.me/cricctt" target={"_blank"} rel="noreferrer"><p>Telegram</p></a>
                        <a href="https://www.facebook.com/cricctt" target={"_blank"} rel="noreferrer"><p>Facebook</p></a>
                    </div>
                </div>
                <div className="copyright">
                    Copyright © 2023 | NE GROUP
                </div>
            </div>
        </div>
    )
}

export default Footer
