import React, { useEffect, useRef } from "react"
import Modal from "react-modal";
import { AiOutlineCloseCircle } from "react-icons/ai"
Modal.setAppElement("#root");

function ModalBox({ children, dOpen, dClose, isClosable }) {
    const modalRef = useRef(null);
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(navigator.userAgent);
    let customStyles = {};
    if (isMobileDevice) {
        customStyles = {
            content: {
                top: '20%',
                left: '2%',
                right: '2%',
                bottom: 'auto',
                borderRadius: "15px",
                // overflow: "hidden",
                // marginRight: '-50%',
                //transform: 'translate(-50%, -50%)',
                padding: "0px",
            },
        };
    } else {
        customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                borderRadius: "30px",
                // overflow: "hidden",
                // marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: "0px",
            },
        };
    }


    function handleClickOutside(event) {
        if (!isClosable) return
        if (modalRef?.current && !modalRef?.current?.contains(event.target)) {
            dClose()
        }
    }
    useEffect(() => {
        if (!dOpen && !isClosable) {
            document.addEventListener("click", handleClickOutside);

        }
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
        // eslint-disable-next-line
    }, [modalRef]);

    function handleCose() {
        if (isClosable) dClose()
    }
    return (
        <Modal
            isOpen={dOpen}
            style={customStyles}
            onRequestClose={handleCose}
        >
            {!isClosable ?
                <div className="modal_header">
                    <div className="close_button" onClick={dClose}>
                        <AiOutlineCloseCircle />
                    </div>
                </div> : null}
            <div className="modal_box_container" ref={modalRef}>
                {children}
            </div>
        </Modal>
    )
}

export default ModalBox;
