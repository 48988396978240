import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    commonError: "",
    hitSixData: {},
    mpData: {},
    hitSixBannerText: {},
    mpBannerText: {},
    vcttBannerText: {},
    socketMatchesData : []
};

export const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        setCommonError: (state, action) => {
            state.commonError = action.payload;
        },
        setHitSixData: (state, action) => {
            state.hitSixData = action.payload;
        },
        setMpData: (state, action) => {
            state.mpData = action.payload;
        },
        setHitSixBannerText: (state, action) => {
            state.hitSixBannerText = action.payload;
        },
        setMpBannerText: (state, action) => {
            state.mpBannerText = action.payload;
        },
        setVcttBannerText: (state, action) => {
            state.vcttBannerText = action.payload;
        },
        setSocketMatchesData: (state, action) => {
            state.socketMatchesData = action.payload;
        },
    }
});

export const {
    setCommonError,
    setHitSixData,
    setMpData,
    setHitSixBannerText,
    setMpBannerText,
    setVcttBannerText,
    setSocketMatchesData
} = homeSlice.actions;


export default homeSlice.reducer;
