import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    favouritesData : [],
    errorMessage : false,
    favouritesLoading : false, 
};

export const favouritesSlice = createSlice({
    name: "favourites",
    initialState,
    reducers: {
        setFavouritesData: (state, action) => {
            state.favouritesData = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setFavouritesLoading: (state, action) => {
            state.favouritesLoading = action.payload;
        }
    }
});

export const {
    setFavouritesData,
    setErrorMessage,
    setFavouritesLoading,
} = favouritesSlice.actions;


export default favouritesSlice.reducer;
