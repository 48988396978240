import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    passwordSuccessMessage: "",
    passwordErrorMessage: "",
    changePinSuccess: false,
    loader: false
};

export const changePasswordSlice = createSlice({
    name: "changePassword",
    initialState,
    reducers: {
        setPasswordSuccessMessage: (state, action) => {
            state.passwordSuccessMessage = action.payload;
        },
        setPasswordErrorMessage: (state, action) => {
            state.passwordErrorMessage = action.payload;
        },
        setChangePinSuccess: (state, action) => {
            state.changePinSuccess = action.payload;
        },
        setLoader: (state, action) => {
            state.loader = action.payload;
        },
    }
});

export const {
    setPasswordErrorMessage,
    setPasswordSuccessMessage,
    setChangePinSuccess,
    setLoader
} = changePasswordSlice.actions;

export default changePasswordSlice.reducer;