import { endpoints } from "../../api/ApiEndPoints";
import { getRequest } from "../../services/apiCaller";
import { setUserDetails } from "../signIn/signSlice";
import { setCommonError, setHitSixBannerText, setHitSixData, setMpBannerText, setMpData, setVcttBannerText } from "./homeSlice";



function getObject(objName, arr) {
    for (let i = 0; i < arr.length; i++) {
        if (objName in arr[i]) {
            return arr[i][objName];
        }
    }
    return null;
}


export const getuserDetails = () => (dispatch) => {
    let userUrl = endpoints.userInfo;
    getRequest(userUrl, true)
        .then((res) => {
            if (res.status === 200) {
                dispatch(setUserDetails(res.data))
            }
        })
        .catch((err) => {
            dispatch(setCommonError(err))
        })
};
export const getleaderBoardDetailsBeforeLogin = () => (dispatch) => {
    let beforeLoginLeaderBoardUrl = endpoints.beforeLoginLeaderBoard;
    dispatch(setHitSixData({}))
    dispatch(setMpData({}))
    getRequest(beforeLoginLeaderBoardUrl, true)
        .then((res) => {
            if (res.status === 200) {
                const hitSixData = getObject("hit6", res.data)
                dispatch(setHitSixData(hitSixData))

                const mpData = getObject("mp", res.data)
                dispatch(setMpData(mpData))
            }
        })
        .catch((err) => {
            dispatch(setCommonError(err))
        })
};
export const getleaderBoardDetailsAfterLogin = () => (dispatch) => {
    let beforeLoginLeaderBoardUrl = endpoints.afterLoginLeaderBoard;
    dispatch(setHitSixData({}))
    dispatch(setMpData({}))
    getRequest(beforeLoginLeaderBoardUrl, true)
        .then((res) => {
            if (res.status === 200) {
                const hitSixData = getObject("hit6", res.data)
                dispatch(setHitSixData(hitSixData))

                const mpData = getObject("mp", res.data)
                dispatch(setMpData(mpData))
            }
        })
        .catch((err) => {
            dispatch(setCommonError(err))
        })
};
export const getBannerText = () => (dispatch) => {
    let gameTextUrl = endpoints.gametext;
    dispatch(setHitSixData({}))
    dispatch(setMpData({}))
    getRequest(gameTextUrl, false)
        .then((res) => {
            if (res.status === 200) {
                const hitSixData = getObject("hit6", [res.data])
                dispatch(setHitSixBannerText(hitSixData))

                const mpData = getObject("mp", [res.data])
                dispatch(setMpBannerText(mpData))

                const vcttData = getObject("vctt", [res.data])
                dispatch(setVcttBannerText(vcttData))
            }
        })
        .catch((err) => {
            dispatch(setCommonError(err))
        })
};

