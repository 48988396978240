import React from "react";

export const ButtonLoader = () => {
    return (
        <React.Fragment>
            <div className="SB-loader">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <circle cx="0" cy="50">
                        <animate attributeName="r" values="0;24;0" dur="1.4s" repeatCount="indefinite"></animate>
                    </circle>
                    <circle cx="60" cy="50">
                        <animate attributeName="r" values="0;26;0" dur="1.4s" begin="0.4s" repeatCount="indefinite"></animate>
                    </circle>
                    <circle cx="130" cy="50">
                        <animate attributeName="r" values="0;24;0" dur="1.4s" begin="0.8s" repeatCount="indefinite"></animate>
                    </circle>
                </svg>
            </div>
        </React.Fragment>
    );
}

export const ContentLoader = () => {
    return (
        <React.Fragment>
            <div className="SB-loader">
                <div className="loaderSVGIcon" title="2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="40px"
                        height="40px"
                        viewBox="0 0 50 50"
                        style={{ enableBackground: "new 0 0 50 50" }}
                        xmlSpace="preserve"
                    >
                        <path d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                            <animateTransform
                                attributeType="xml"
                                attributeName="transform"
                                type="rotate"
                                from="0 25 25"
                                to="360 25 25"
                                dur="0.6s"
                                repeatCount="indefinite"
                            ></animateTransform>
                        </path>
                    </svg>
                </div>
            </div>
        </React.Fragment>
    )
}


export const HomePageLoader = () => {
    return (
        <React.Fragment>
            <div className="skeleton_Wrapper">
                <div className="container">
                    <div className="card">
                        <div className="card loading">
                            <div className="image">

                            </div>
                        </div>
                    </div>
                    <div className="cards">
                        <div className="card">
                            <div className="card loading">
                                <div className="image"></div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card loading">
                                <div className="image"></div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card loading">
                                <div className="image"></div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card loading">
                                <div className="image"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}