import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFromLocalStorage } from '../../services/helper/Index';

function VcttGame() {
    const [accessToken, setAccessToken] = useState("");
    const [gameTab, setGameTab] = useState("");
    const { userDetails } = useSelector(state => state.signIn)

    const { tab } = useParams()

    const memoizedGetFromLocalStorage = useCallback(getFromLocalStorage, []);


    useEffect(() => {
        const token = memoizedGetFromLocalStorage("gk");
        setAccessToken(token);
    }, [memoizedGetFromLocalStorage]);

    useEffect(() => {
        if (tab === undefined) {
            setGameTab("")
        } else {
            setGameTab(tab)
        }
    }, [tab,accessToken, userDetails]);



    if (!accessToken) return null;
    return (
        <React.Fragment>
            <iframe
                className="gameScreen_frame"
                title="hit-six-game"
                src={`https://dev-games-rgs.sportsit-tech.net/games/virtualCatchTheTrend/index.html#/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDIzZTViNGIwOTM3ZjFhZTRiZWQ4OTAiLCJyb2xlIjoidXNlciIsImVtYWlsIjoid2VidGVzdGluZzc1N0BnbWFpbC5jb20iLCJyZWFzb24iOiJsb2dpbiIsImlhdCI6MTY4MTIxMjMxMCwiZXhwIjoxNzEyNzQ4MzEwfQ.FlRt9xrlp1E2F-AXU8UlIwW2Rr2v4h4PfczIAazBJ74&playerId=6423e5b4b0937f1ae4bed890&playerName=aKasH&platformId=0&operatorId=demo&brand=demo&currencyCode=INR&languageCode=en&region=IN`}
                sandbox="allow-same-origin allow-scripts"
            >
            </iframe>
        </React.Fragment>
    )
}

export default VcttGame