import React from "react";
import { BsCheck } from "react-icons/bs";
import { useDispatch, connect } from "react-redux";
import { AiFillEyeInvisible } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import { useState } from "react";
import { setPin, validatePin } from "./recoverPinApi";
import { setUpdatePinResponseSuccess } from "./recoverPinSlice";
import { useNavigate, useLocation } from "react-router-dom";


function UpdatePin(props) {
    const location = useLocation();
    const searchArray = location.search.split("=");
    const code = searchArray[searchArray.length - 1];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [eyeIcon, setEyeIcon] = useState(false);
    const [passwordDetails, setPasswordDetails] = useState({
        newpin: "",
        confirmpin: "",
    });
    const [confirmEyeIcon, setConfirmEyeIcon] = useState(false);
    const [newPinError, setNewPinError] = useState("");
    const [confirmPinError, setConfirmPinError] = useState("");
    const handleEyeIconPin = () => {
        setEyeIcon(!eyeIcon);
    }
    const handleConfirmEyeIconPin = () => {
        setConfirmEyeIcon(!confirmEyeIcon);
    }

    const inputHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === "newpin") {
            setNewPinError("");
        }
        if (name === "confirmpin") {
            setConfirmPinError("");
        }
        setPasswordDetails({ ...passwordDetails, [name]: value })
        dispatch(setUpdatePinResponseSuccess(""))
        if (name === "newpin") {
            setNewPinError("");
        }
        if (name === "confirmpin") {
            setConfirmPinError("");
        }
    }

    const handleSetPin = (passwordDetails) => {
        if (passwordDetails.newpin === "" && passwordDetails.confirmpin === "") {
            setNewPinError("Please Enter New Password");
            setConfirmPinError("Please Enter Confirm Password");
        } else if (passwordDetails.newpin === "") {
            setNewPinError("Please Enter New Password");
        } else if (passwordDetails.newpin.length < 6) {
            setNewPinError("password must have 6 alphanumeric characters");
        } else if (passwordDetails.confirmpin === "") {
            setConfirmPinError("Please Enter Confirm Password");
        } else if (!validatePin(passwordDetails.newpin)) {
            setNewPinError("password should have at least 1 lowercase letter, 1 uppercase letter, 1 number.");
        }
        else if (passwordDetails.newpin !== passwordDetails.confirmpin) {
            setConfirmPinError("New Password and Confirm password should be same");
        } else {
            setNewPinError("");
            setConfirmPinError("");
            dispatch(setPin(code, navigate, passwordDetails.confirmpin));
        }
    }

    return (
        <>
            <div className="update_pin_wrapper">
                <div className="container">
                    <div className="recover_details_body">
                        <div className="pin_text">
                            <p>Update your PIN below</p>
                        </div>
                        <div className="form_wrapper">
                            <div className="form_container">
                                <div className="form_group">
                                    <label htmlFor="pin">New PIN</label>
                                    <div className="form_input">
                                        <input type={eyeIcon ? "text" : "password"}
                                            id="pin"
                                            name="newpin"
                                            onChange={(e) => inputHandler(e)}
                                            value={passwordDetails.newpin}
                                            minLength={6}
                                            maxLength={15} />
                                        <span onClick={() => handleEyeIconPin()}>
                                            {eyeIcon ? <AiFillEye /> : <AiFillEyeInvisible />}
                                        </span>
                                    </div>
                                    <div className='error-msg'>
                                        {newPinError}
                                    </div>
                                    <label htmlFor="pin"> Confirm New PIN</label>
                                    <div className="form_input">
                                        <input type={confirmEyeIcon ? "text" : "password"}
                                            id="pin"
                                            name="confirmpin"
                                            onChange={(e) => inputHandler(e)}
                                            value={passwordDetails.confirmpin}
                                            minLength={6}
                                            maxLength={15}
                                        />
                                        <span onClick={() => handleConfirmEyeIconPin()}>
                                            {confirmEyeIcon ? <AiFillEye /> : <AiFillEyeInvisible />}
                                        </span>
                                    </div>
                                    <div className='error-msg'>
                                        {confirmPinError}
                                    </div>
                                    <p>Your PIN:</p>
                                    <div className="pin_contents">
                                        <div className="pin_content">
                                            <span><BsCheck className="check_icon" /></span>
                                            <p>Must have at least 6 alphanumeric characters </p>
                                        </div>
                                        <div className="pin_content">
                                            <span><BsCheck className="check_icon" /></span>
                                            <p>One of which will need to be numeric</p>
                                        </div>
                                        <div className="pin_content">
                                            <span><BsCheck className="check_icon" /></span>
                                            <p>Alpha characters will need to be a combination of upper and lower case (at least one of each)</p>
                                        </div>
                                    </div>
                                    <button className="btn form_btn btn-strech" onClick={() => handleSetPin(passwordDetails)}>SET NEW PIN</button>
                                    <div className='error-msg'>
                                        {props?.updatePinResponseError}
                                        {props?.checkPinError}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function mapStateToProps(state) {
    return {
        updatePinResponseError: state?.recoverPin?.updatePinResponseError,
        checkPinError: state?.recoverPin?.checkPinError
    };
}
export default connect(mapStateToProps)(UpdatePin)