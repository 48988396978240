import React from 'react'
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from 'react-router';

function BackButton() {
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }
  return (
    <div className='back-button pointer' onClick={goBack}>
      <div className="backButton_Icon">
        <MdArrowBackIosNew />
      </div>
      <div className="backButton_Text">BACK</div>
    </div>
  )
}

export default BackButton