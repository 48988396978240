import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import LOGO from "../../assets/images/png/logo.png";
import signUpBg from "../../assets/images/png/signUp.png";
import BackButton from '../common/BackButton';
import { ButtonLoader } from '../common/Loader';
import { emailVerification } from './SignUpApi';
import {
    setEmail,
    setEmailError,
    setErrorMessage
} from "./SignUpSlice";
import { setToLocalStorage } from '../../services/helper/Index';

function Signup() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const regExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const {
        email,
        errorMessage,
        emailError,
        loader
    } = useSelector((state) => state.signup);

    // const [isPhoneActive, setisPhoneActive] = useState(true)
    // const handleActiveTab = () => {
    //     setisPhoneActive(!isPhoneActive)
    // }    

    const queryParameters = new URLSearchParams(window.location.search);
    const code = queryParameters.get("code")
    const btagEmail = queryParameters.get("email")

    useEffect(() => {
        if (code) {
            setToLocalStorage("ref", code);
        }
        if (btagEmail) {
            dispatch(setEmail(btagEmail));
        }
    }, [code, btagEmail]);

    useEffect(() => {
        dispatch(setEmailError(false));
    }, []);

    const onChangeText = (e) => {
        dispatch(setEmailError(false));
        dispatch(setEmail(e.target.value));
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        if (email === "") {
            dispatch(setErrorMessage("Enter Email"));
            dispatch(setEmailError(true));
        } else if (!regExp.test(email)) {
            dispatch(setErrorMessage("Invalid Email"));
            dispatch(setEmailError(true));
        } else {
            dispatch(setEmailError(false))
            dispatch(emailVerification(email, navigate));
        }
    };

    return (
        <React.Fragment>
            <div className="signup_wrapper" role={"none"}>
                <BackButton />
                <div className='container signup_container'>
                    <div>
                        <Link to={"/"}>
                            <img src={LOGO} alt="logo" className="signup_logo" />
                        </Link>
                        <div className='sign_title'>
                            SIGN UP
                        </div>
                        <p>And Get a Great Welcome Bonus</p>
                        <span>Receive 8 Coins Immediately</span>
                    </div>
                    <form onSubmit={handleSubmit} className="signup_box">
                        <div className="signup_content">
                            {/* <div className="signup_option">
                                <div className={isPhoneActive ? "signup_phone active" : "signup_phone"} onClick={() => handleActiveTab()}>Phone Number</div>
                                <div className={!isPhoneActive ? "signup_email active" : "signup_email"} onClick={() => handleActiveTab()}>Email</div>
                            </div> */}

                            {/* {isPhoneActive ? <div className="form_group email_group">
                                <input placeholder='Enter Phone Number'></input>
                                <button className='btn'>SIGN UP</button>
                            </div>
                                :
                                null} */}
                            <div className="form_group phone_group">
                                <input
                                    placeholder={"Enter Email"}
                                    type={"text"}
                                    onChange={(e) => onChangeText(e)}
                                    value={email}
                                />
                                {
                                    emailError && <div className='error_message'>{errorMessage}</div>
                                }
                                <button type='submit' className='btn'>{loader ? <ButtonLoader /> : "SIGN UP"}</button>
                            </div>
                        </div>
                    </form>
                    <div className='signUpBackground'>
                        <img src={signUpBg} alt={"bg"} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default Signup